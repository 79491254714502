// Libs
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {
  isToday,
  getDay,
} from 'date-fns';

// Assets
import splash from '../../assets/splash.svg';
import firework from '../../assets/fireworks.svg';
import spinner from '../../assets/spinner.svg';

// Validation
import {
  cpfValidate,
  cpfMask,
} from '../../utils/validation';

const Container = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding: 1rem 0;
  background: #BCD0D9;
`;

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Title = styled.h1`
  width: 80%;
  margin: 2.5rem auto 1rem;
  color: #1B4487;
  font: 400 1.5rem 'Open Sans', sans-serif;
`;

const Form = styled.form`
  width: 80%;
  margin: 3rem auto 0;
`;

const Field = styled.fieldset`
  margin: 1rem 0;
  padding: 0;
  border: none;
`;

const Label = styled.label`
  display: block;
  margin-bottom: .5rem;
  color: #1B4487;
  font: 400 .9rem 'Open Sans', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  height: 48px;
  border: ${props => props.hasError ? '2px solid #D56161' : '2px solid #1B4487'};
  border-radius: 5px;
  color: ${props => props.hasError ? '#D56161' : '#1B4487'};
  font: 400 .9rem 'Open Sans', sans-serif;
  background-color: #EBEBF04d;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 65px;
  margin: 2rem auto 0;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  color: #1B4487;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background-color: #8198BD4d;
`;

const ErrorMessage = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #D56161;
  font: 400 .9rem 'Open Sans', sans-serif;
`;

const Splash = styled.div`
  content: '';
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background: url(${splash}) no-repeat center;
  background-size: contain;
  transform: translate(-45%, 35%);
`;

const Firework = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  height: 110px;
  background: url(${firework}) no-repeat center;
  background-size: contain;
  transform: translate(50%, -50%);
  z-index: 3;
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 500px;
  min-height: 150px;
  margin: 3rem auto 0;
  padding: 1rem;
  border-radius: 5px;
  background: ${props => props.backgroundColor};
`;

const CardTitle = styled.h4`
  width: 100%;
  margin: 0;
  text-align: center;
  color: #fff;
  font: 400 1.1rem 'Open Sans', sans-serif;
`;

const CardName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 29px;
  margin: 1rem auto;
  color: #fff;
  font: 700 .9rem 'Open Sans', sans-serif;
  background: #FFFFFF1A;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff4d;
`;

const Day = styled.span`
  color: #fff;
  font: 400 1.25rem 'Open Sans', sans-serif;

  span {
    display: inline-block;
    margin-left: .5rem;
  }
`;

const getFormattedDay = (date) => {
  var day = date
    .split('T')[0]
    .split('-');

  return `${day[2]}/${day[1]}/${day[0]}`;
};

const getFormattedHour = (date) => {
  var hrs = date
    .split('T')[1]
    .split(':');

  return `${hrs[0]}:${hrs[1]}`;
};

const Validation = () => {
  const [cpf, setCPF] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [userData, setUserData] = useState({});

  const applyCPFValidation = () => {
    if (`${process.env.REACT_APP_ENVIROMENT}` === 'prod') {
      return true;
    }

    return false;
  };

  const handleAuth = async (ev) => {
    ev.preventDefault();

    try {
      if (applyCPFValidation()) {
        const cpfValidation = cpfValidate(cpf);
  
        setCpfError(!cpfValidation);
        if (!cpfValidation) return;
      } else {
        const cpfValidation = cpf.length === 14;
        setCpfError(!cpfValidation);
        if (!cpfValidation) return;
      }

      setLoading(true);

      const identifier = cpf.replace(/[.-]/g, '');

      const response = await axios.get(
        `${process.env.REACT_APP_VACCINATION_API}/info/${identifier}`,
        {
          headers: {
            'X-Sirio-Token': '7550013025'
          }
        }
      );

      setUserData({
        id: response.data.data.id,
        name: response.data.data.name,
        status: response.data.data.status,
        vaccinationStartDate: response.data.data.start_date,
        vaccinationEndDate: response.data.data.end_date
      });

      setCpfError(false);
      setLoading(false);
    } catch (err) {
      setCpfError(false);
      setLoading(false);
      if (err.response.status === 404) {
        setUserError(false);
        setUserData({
          type: 'not-found',
          id: 10,
          status: 'Não Encontrado'
        });
      }
    }
  };

  const chooseDay = (date) => {
    const day = getDay(new window.Date(date));

    switch (day) {
      case 0:
        return 'Dom';
      case 1:
        return 'Seg';
      case 2:
        return 'Ter';
      case 3:
        return 'Qua';
      case 4:
        return 'Qui';
      case 5:
        return 'Sexta';
      case 6:
        return 'Sab';
      default:
        return '';
    }
  };

  const handleReset = () => {
    setCPF('');
    setCpfError(false);
    setUserData({});
  };

  const renderError = () => {
    if (userError) {
      return (
        <ErrorMessage>
          Usuário não cadastrado na primeira fase!
        </ErrorMessage>
      );
    }

    return null;
  };

  const renderForm = () => (
    <Form
      onSubmit={handleAuth}
    >
      <Field>
        <Label>
          CPF
        </Label>
        <Input
          type="text"
          inputMode="numeric"
          required
          value={cpfMask(cpf)}
          hasError={cpfError}
          onChange={(ev) => {
            setCPF(cpfMask(ev.target.value));
          }}
          onBlur={(ev) => {
            const cpf = cpfMask(ev.target.value);

            if (applyCPFValidation()) {
              setCpfError(!cpfValidate(cpf));
            } else {
              setCpfError(cpf.length === 14 ? false : true);
            }
          }}
        />
      </Field>
      {renderError()}
      {isLoading
        ? <Spinner src={spinner} />
        : <Button>
          Validar
          </Button>
      }
    </Form>
  );

  const renderInfo = () => {
    if (userData.type === 'not-found') {
      return (
        <Card
          backgroundColor="#071938EC"
        >
          <CardTitle>Usuário não encontrado</CardTitle>
        </Card>
      );
    }

    const today = isToday(new window.Date(userData.vaccinationStartDate));

    return (
      <Card
        backgroundColor="#071938EC"
      >
        <CardTitle>Informações:</CardTitle>
        <CardName>
          {userData.name}
        </CardName>
        <Info>
          <Day>Status:</Day>
          <Day>{userData.status}</Day>
        </Info>
        {userData.status !== 'Não Agendado' && (
          <Info>
            <Day>
              {today ? 'Hoje' : chooseDay(userData.vaccinationStartDate)}
              <span>{getFormattedHour(userData.vaccinationStartDate)}</span>
            </Day>
            <Day>
              {getFormattedDay(userData.vaccinationStartDate)}
            </Day>
          </Info>
        )}
      </Card>
    )
  };

  return (
    <Container>
      <Splash />
      <Firework />
      <Inner>
        <Title>
          Campanha de <br /> Vacinação
        </Title>
        {userData.status ? renderInfo() : renderForm()}
        {userData.status && (
          <Button
            style={{ width: '80%' }}
            onClick={handleReset}
          >
            Buscar novamente
          </Button>
        )}
      </Inner>
    </Container>
  );
};

export default Validation;