// Libs
import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';

// Validation
import Splash from './Splash';

const Layouts = () => {
  return (
    <Switch>
      <Route
        render={(props) => {
          return <Splash {...props} />
        }}
      />
    </Switch>
  );
};

export default Layouts;