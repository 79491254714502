// Libs
import React from 'react';
import styled from 'styled-components';

// Assets
import warning from '../../assets/warning.svg';
import splash from '../../assets/splash.svg';
import fireworks from '../../assets/fireworks-white.svg';

// Components
import UserHeader from '../../components/UserHeader';

const Container = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding-bottom: ${props => props.selected ? '77px' : '1rem'};
  background: #BCD0D9;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 90px);

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Splash = styled.div`
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 150px;
  height: 150px;
  background: url(${splash}) no-repeat center;
  background-size: contain;
  transform: translate(50%, 30%);
`;

const Firework = styled.div`
  display: block;
  position: absolute;
  bottom: 25%;
  left: 0;
  width: 150px;
  height: 150px;
  background: url(${fireworks}) no-repeat center;
  background-size: contain;
  transform: translateX(-75%);
`;

const Title = styled.h1`
  width: 80%;
  margin: 2.5rem auto 2rem;
  text-align: center;
  color: #1B4487;
  font: 400 1.5rem 'Open Sans', sans-serif;
`;

const WarningIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #8198BD4d;
`;

const WarningIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const WarningMessage = styled.p`
  width: 80%;
  margin: 2rem auto 0;
  text-align: center;
  color: #1B4487;
  font: 300 .95rem 'Open Sans', sans-serif;
`;

const WarningOption = styled.p`
  width: 80%;
  margin: 2rem auto 0;
  text-align: center;
  color: #1B4487;
  font: 400 1rem 'Open Sans', sans-serif;
`;

const WarningAdvice = styled.p`
  width: 80%;
  margin: 2rem auto 0;
  text-align: center;
  color: #8198BD;
  font: 300 .95rem 'Open Sans', sans-serif;
  font-style: italic;
`;

const Blocked = ({
  type,
  message,
  campaign
}) => {
  if (type === 'blocked-question') {
    return (
      <Container>
        <UserHeader
          background="#BCD0D9"
          color="#1B4487"
        />
        <Splash />
        <Firework />
        <Inner>
          <WarningIconContainer>
            <WarningIcon src={warning} />
          </WarningIconContainer>
          <WarningOption>Você escolheu a opção:</WarningOption>
          <WarningAdvice>
            {message.replace('[]', campaign)}
          </WarningAdvice>
        </Inner>
      </Container>
    );
  }

  return (
    <Container>
      <UserHeader
        background="#BCD0D9"
        color="#1B4487"
      />
      <Splash />
      <Firework />
      <Inner>
        <Title>
          Usuário Bloqueado
        </Title>
        <WarningIconContainer>
          <WarningIcon src={warning} />
        </WarningIconContainer>
        <WarningMessage>
          {campaign === 'COVID'
            ? 'Seu usuário foi bloqueado por não comparecimento na primeira dose de vacinação contra o COVID-19, para esclarecimentos procure o gestor de sua equipe'
            : 'Seu usuário foi bloqueado por não comparecimento na primeira dose de vacinação contra a Gripe, para esclarecimentos procure o gestor de sua equipe'
          }
        </WarningMessage>
      </Inner>
    </Container>
  );
};

export default Blocked;