// Libs
import React from 'react';
import ReactDOM from 'react-dom';

// CSS
import './index.css';

// Components
import App from './App';

// Mocks
// import { mockedServer } from './server/server';

// mockedServer();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
