// Libs
import React from 'react';
import styled from 'styled-components';

// Components
import CampaignsList from '../../components/CampaignList';
import Header from '../../components/Header';
import UserHeader from '../../components/UserHeader';

const CampaingsContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #1B4487;
`;

const Camapigns = ({
  campaigns,
  campaignLoader,
  checkAppointment
}) => {
  return (
    <CampaingsContainer>
      <UserHeader />
      <Header />
      <CampaignsList
        campaigns={campaigns}
        campaignLoader={campaignLoader}
        checkAppointment={checkAppointment}
      />
    </CampaingsContainer>
  )
};

export default Camapigns;