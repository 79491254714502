// Libs
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Assets
import spinner from '../../assets/spinner.svg';

// Pages
import Home from '../Home/Home';
import Screening from '../Screening/Screening';
import Appointment from '../Appointment/Appointment';
import Blocked from '../Blocked/Blocked';
import Campaigns from '../Campaigns/Campaigns';

// Components
import Header from '../../components/Header';

const Container = styled.div`
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: #1B4487;
`;

const CampaingsContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #1B4487;
`;

const Spinner = styled.img`
  width: 150px;
  height: 150px;
`;

const Splash = (props) => {
  const [loader, setLoader] = useState(true);
  const [screen, setScreen] = useState(null);
  const [message, setMessage] = useState(null);
  const [campaign, setCampaign] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [campaignLoader, setCampaignLoader] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const { token, identifier } = JSON.parse(window.localStorage.getItem(
    `${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`
  ));

  const getProfile = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const campaigns = await axios.get(
        `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/campaignPlan`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setCampaigns(campaigns.data.data);
      setCampaign(response.data.data.questionnaire_type);
      setLoader(false);

      setScreen('home');
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const checkAppointment = async (campaign) => {
    try {
      setCampaignLoader(campaign.id);
      setSelectedCampaign(campaign);

      const dates = await axios.get(
        `${process.env.REACT_APP_VACCINATION_API}/vaccination/dates?vaccineDose=${campaign.vaccineDose}&vaccineType=${campaign.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (dates.data.data.length > 0) {
        setScreen('screening');
      } else {
        setScreen('home');
      }

      setCampaignLoader(null);
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  useEffect(() => {
    getProfile();
  }, [])

  useEffect(() => {
    if (screen === 'campaigns') {
      getProfile();
    }
  }, [screen])

  if (screen === 'campaigns') {
    return (
      <Campaigns
        campaigns={campaigns}
        campaignLoader={campaignLoader}
        checkAppointment={checkAppointment}
      />
    );
  }

  if (screen === 'home') {
    return (
      <Home
        {...props}
        setScreen={setScreen}
        campaign={campaign}
        selectedCampaign={selectedCampaign}
        campaigns={campaigns}
        campaignLoader={campaignLoader}
        checkAppointment={checkAppointment}
        setCampaign={setSelectedCampaign}
      />
    );
  }

  if (screen === 'screening') {
    return (
      <Screening
        {...props}
        setScreen={setScreen}
        campaign={campaign}
      />
    );
  }

  if (screen === 'appointment') {
    return (
      <Appointment
        {...props}
        setParentScreen={setScreen}
        campaign={campaign}
        selectedCampaign={selectedCampaign}
      />
    );
  }

  if (screen === 'blocked') {
    return (
      <Blocked
        campaign={campaign}
      />
    );
  }

  if (screen === 'blocked-question') {
    return (
      <Blocked
        type='blocked-question'
        message={message}
        campaign={campaign}
      />
    );
  }

  if (loader) {
    return (
      <Container>
        <Spinner src={spinner} />
      </Container>
    )
  }

  return (
    <CampaingsContainer>
      <Header
        background="#FFFFFF41"
        color="#8198BD"
      />
    </CampaingsContainer>
  )
};

export default Splash;