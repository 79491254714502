// Libs
import React, { Fragment, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  isToday,
  getDay,
} from 'date-fns';
import values from 'lodash.values';
import axios from 'axios';

// Assets
import arrow from '../../assets/arrow.svg';
import download from '../../assets/download.svg';
import success from '../../assets/success.svg';
import warning from '../../assets/warning.svg';
import spinner from '../../assets/spinner-white.svg';
import arrowWhite from '../../assets/back.svg';

// Components
import Header from '../../components/Header';
import UserHeader from '../../components/UserHeader';

// Animations
const TransitionButton = keyframes`
  0% { bottom: -77px };
  100% { bottom: 0 };
`;

const Container = styled.div`
  position: relative;
  background-color: #1B4487;
  height: 100%;
  min-height: 100vh;
  padding-bottom: ${props => props.isSelected ? '95px' : '1rem'};
`;

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Title = styled.h1`
  margin: 1.5rem 0;

  text-align: center;
  color: #fff;
  font: 400 1.35rem 'Open Sans', sans-serif;
`;

const Message = styled.h2`
  width: 80%;
  margin: 1.5rem auto;

  text-align: center;
  color: #FFE4D6;
  font: 400 0.875rem 'Open Sans', sans-serif;
  font-style: italic;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  margin: .85rem 0;
  padding: 0 5%;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: ${props => props.selected ? '#508CF0' : '#8198BD4D'};
`;

const Day = styled.span`
  color: #fff;
  font: 400 1.1rem 'Open Sans', sans-serif;

  span {
    display: inline-block;
    margin-left: .5rem;
  }
`;

const Date = styled.span`
  color: #fff;
  font: 400 1.1rem 'Open Sans', sans-serif;
  letter-spacing: 3px;
`;

const Location = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 58px;
  margin: ${props => props.hasMargin ? '2rem auto' : '0 auto'};
  padding: 0 2.5%;
  border: 2px solid #508CF0;
  border-radius: 4px;
  background-color: #8198BD4d;
`;

const LocationTime = styled.span`
  display: block;
  width: 45%;
  color: #fff;
  font: 700 .8rem 'Open Sans', sans-serif;
`;

const LocationLabel = styled.span`
  color: #fff;
  font: 400 .6rem 'Open Sans', sans-serif;
  font-style: italic;
`;

const AppointmentItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 47.5%;
  margin-bottom: 5%;
  padding: .75rem;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#508CF0' : '#8198BD4d'};
`;

const AppointmentTitle = styled.span`
  color: #fff;
  font: 400 1.125rem 'Open Sans', sans-serif;
`;

const AppointmentLine = styled.div`
  width: 100%;
  margin: 4px 0 6px;
  height: 0.5px;
  background-color: ${props => props.selected ? '#fff' : '#1B4487'};
`;

const AppointmentMessage = styled.p`
  width: 100%;
  margin: 0;
  margin-top: .5rem;
  color: #FFE4D6;
  font: 400 .7rem 'Open Sans', sans-serif;
`;

const AppointmentDate = styled.p`
  width: 80%;
  margin: 1.5rem auto;
  color: #FFF;
  text-align: center;
  font: 700 1rem 'Open Sans', sans-serif;
`;

const SuccessContainer = styled.div``;

const SuccessIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #8198BD4d;
`;

const SuccessIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 77px;
  padding: 0 5%;
  border: none;
  cursor: pointer;
  outline: none;
  color: #1B4487;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background-color: #fff;
  animation: ${TransitionButton} .5s;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    background: url(${arrow}) no-repeat center center;
    background-size: contain;
  }

  @media (min-width: 768px) {
    position: static;
    width: 80%;
    margin: 0 auto;
    height: 50px;
    border-radius: 4px;
    animation: none;
  }
`;

const TermLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 50px;
  margin: 1.5rem auto;
  padding: 0 5%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  color: #1B4487;
  text-decoration: none;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background-color: #fff;

  &::after {
    content: '';
    width: 25px;
    height: 25px;
    background: url(${download}) no-repeat center center;
    background-size: contain;
  }
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const MoreDates = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: .5rem auto 0;
  text-align: center;
  color: #fff;
  font: 400 1rem 'Open Sans', sans-serif;

  &::after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: .5rem;
    background: url(${arrowWhite}) no-repeat center;
    background-size: contain;
    transform: rotate(90deg);
  }
`;

const orderList = (list) => {
  return list
    .sort(function (a, b) {
      return a.vaccinationStartDate.localeCompare(b.vaccinationStartDate);
    })
};

const getFormattedDay = (date) => {
  var day = date
    .split('T')[0]
    .split('-');

  return `${day[2]}/${day[1]}/${day[0]}`;
};

const getFormattedHour = (date) => {
  var hrs = date
    .split('T')[1]
    .split(':');

  return `${hrs[0]}:${hrs[1]}`;
};

const Appointment = ({
  setParentScreen,
  selectedCampaign,
}) => {
  const [step, setStep] = useState('date');
  const [dates, setDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDates, setLoadingDates] = useState(true);
  const [timePagination, setTimePagination] = useState(6);
  const [dayPagination, setDayPagination] = useState(6);

  const { token, identifier } = JSON.parse(window.localStorage.getItem(
    `${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`
  ));

  const fetchData = async () => {
    try {
      const availableDays = {};

      const response = await axios.get(
        `${process.env.REACT_APP_VACCINATION_API}/vaccination/dates?vaccineDose=${selectedCampaign.vaccineDose}&vaccineType=${selectedCampaign.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      response.data.data.forEach((date, index) => {
        const day = getFormattedDay(date.vaccinationStartDate);
        const hour = getFormattedHour(date.vaccinationStartDate);

        if (availableDays[day]) {
          availableDays[day] = [
            ...availableDays[day],
            {
              ...date,
              id: index + 1,
              day,
              hour,
            }
          ];
        } else {
          availableDays[day] = [{
            ...date,
            id: index + 1,
            day,
            hour,
          }];
        }
      });

      setDates(availableDays);
      setLoadingDates(false);
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  useEffect(() => {
    if (selectedCampaign) {
      fetchData();
    }
  }, [selectedCampaign]);

  const handleAppointmentCreation = async () => {
    try {
      setIsButtonDisabled(true);
      setLoading(true);

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination`,
        data: {
          data: {
            identifier: identifier,
            vaccineType: selectedCampaign.id,
            vaccinationStartDate: selectedAppointment.vaccinationStartDate,
            vaccinationEndDate: selectedAppointment.vaccinationEndDate,
            vaccineDose: selectedAppointment.vaccineDose
          }
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      setIsButtonDisabled(false);
      setLoading(false);
      setStep('success');
    } catch (err) {
      if (err.response.status === 403) {
        window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
        window.location = `${window.location.origin}/login`;
      } else {
        setIsButtonDisabled(false);
        setLoading(false);
        setStep('unavailable');
      }
    }
  };

  const handleAppointmentRetry = async () => {
    await fetchData();

    setSelectedDate({});
    setSelectedAppointment({});
    setStep('date');
  };

  const chooseDay = (date) => {
    const day = getDay(new window.Date(date));

    switch (day) {
      case 0:
        return 'Dom';
      case 1:
        return 'Seg';
      case 2:
        return 'Ter';
      case 3:
        return 'Qua';
      case 4:
        return 'Qui';
      case 5:
        return 'Sexta';
      case 6:
        return 'Sab';
      default:
        return '';
    }
  };

  const chooseButtonText = () => {
    switch (step) {
      case 'date':
        return 'Confirmar Data de Vacinação';
      case 'time':
        return 'Confirmar Horário de Vacinação';
      case 'summary':
        return 'Confirmar Agendamento';
      case 'success':
        return 'Ver Cartão de Vacinação';
      case 'unavailable':
        return 'Escolher outro horário';
      default:
        return '';
    }
  };

  const handleNextStep = () => {
    switch (step) {
      case 'date':
        setStep('time');
        return;
      case 'time':
        setStep('summary');
        return;
      case 'summary':
        handleAppointmentCreation();
        return;
      case 'success':
        setParentScreen('home');
        return;
      case 'unavailable':
        handleAppointmentRetry();
        return;
      default:
        return null;
    }
  };

  const renderDates = () => {
    return values(dates).slice(0, dayPagination).map((item, index) => {
      const date = item[0]
      const today = isToday(new window.Date(date.vaccinationStartDate));

      return (
        <Item
          key={index}
          onClick={() => {
            setSelectedDate({
              day: date.day,
              list: orderList(item)
            });

          }}
          selected={date.day === selectedDate.day}
        >
          <Day>
            {today ? 'Hoje' : chooseDay(date.vaccinationStartDate)}
          </Day>
          <Date>
            {getFormattedDay(date.vaccinationStartDate)}
          </Date>
        </Item>
      );
    });
  }

  const renderDateScreen = () => {
    if (values(dates).length > 0) {
      return (
        <Fragment>
          <List>
            {renderDates()}
          </List>
          {dayPagination < values(dates).length && (
            <MoreDates
              onClick={() => {
                setDayPagination(dayPagination + 6);
              }}
            >
              Carregar mais datas
            </MoreDates>
          )}
          {selectedDate.day && (
            <NextButton
              onClick={handleNextStep}
              disabled={isButtonDisabled}
            >
              {chooseButtonText()}
            </NextButton>
          )}
        </Fragment>
      )
    }
  };

  const chooseCampaignType = (type) => {
    switch(type) {
      case 'COVID':
        return 'Vacinação para COVID-19';
      case 'COVID-19 Dose Adicional':
        return 'Vacinação para COVID-19 Dose Adicional';
      default:
        return 'Vacinação para Gripe';
    }
  }

  const renderAppointments = () => {
    return selectedDate.list.slice(0, timePagination).map((item) => {
      const today = isToday(new window.Date(item.vaccinationStartDate));

      return (
        <AppointmentItem
          key={item.id}
          onClick={() => {
            setSelectedAppointment(item);
          }}
          selected={item.id === selectedAppointment.id}
        >
          <AppointmentTitle>
            {today ? 'Hoje' : chooseDay(item.vaccinationStartDate)}
          </AppointmentTitle>
          <AppointmentTitle>
            {item.hour}
          </AppointmentTitle>
          <AppointmentLine
            selected={item.id === selectedAppointment.id}
          />
          <AppointmentMessage>
            {chooseCampaignType(selectedCampaign.type)}
          </AppointmentMessage>
        </AppointmentItem>
      )
    });
  };

  const renderTimeScreen = () => {
    return (
      <Fragment>
        <Location>
          <LocationTime>
            {selectedDate.list[0].vaccinationLocationName}
          </LocationTime>
          <LocationLabel>
            {selectedDate.list[0].vaccinationLocationAddress}
          </LocationLabel>
        </Location>
        <AppointmentDate>
          {getFormattedDay(selectedDate.list[0].vaccinationStartDate)}
        </AppointmentDate>
        <List>
          {renderAppointments()}
        </List>
        {timePagination < selectedDate.list.length && (
          <MoreDates
            onClick={() => {
              setTimePagination(timePagination + 6);
            }}
          >
            Carregar mais horários
          </MoreDates>
        )}
        {selectedAppointment.id && (
          <NextButton
            onClick={handleNextStep}
            disabled={isButtonDisabled}
          >
            {chooseButtonText()}
          </NextButton>
        )}
      </Fragment>
    )
  };

  const renderSummaryScreen = () => {
    const today = isToday(new window.Date(
      selectedAppointment.vaccinationStartDate
    ));

    return (
      <Fragment>
        <Location>
          <LocationTime>
            {selectedDate.list[0].vaccinationLocationName}
          </LocationTime>
          <LocationLabel>
            {selectedDate.list[0].vaccinationLocationAddress}
          </LocationLabel>
        </Location>
        <Item
          style={{
            width: '80%',
            margin: '1rem auto',
            border: '2px solid #508CF0',
            cursor: 'initial'
          }}
        >
          <Day>
            {today
              ? 'Hoje'
              : chooseDay(selectedAppointment.vaccinationStartDate)
            },
            <span>{selectedAppointment.hour}</span>
          </Day>
          <Date>
            {getFormattedDay(selectedAppointment.vaccinationStartDate)}
          </Date>
        </Item>
        <NextButton
          onClick={handleNextStep}
          disabled={isButtonDisabled}
        >
          {chooseButtonText()}
        </NextButton>
      </Fragment>
    );
  };

  const renderSuccessScreen = () => {
    const today = isToday(new window.Date(
      selectedAppointment.vaccinationStartDate
    ));

    return (
      <Fragment>
        <SuccessContainer>
          <Title>
            Agendamento Realizado
          </Title>
          <SuccessIconContainer>
            <SuccessIcon
              src={success}
            />
          </SuccessIconContainer>
          <Message>
            Você será vacinado em
          </Message>
          <Location>
            <LocationTime>
              {selectedAppointment.vaccinationLocationName}
            </LocationTime>
            <LocationLabel>
              {selectedAppointment.vaccinationLocationAddress}
            </LocationLabel>
          </Location>
          <Item
            style={{
              width: '80%',
              margin: '1rem auto',
              border: '2px solid #508CF0',
              cursor: 'initial'
            }}
          >
            <Day>
              {today
                ? 'Hoje'
                : chooseDay(selectedAppointment.vaccinationStartDate)
              },
              <span>{selectedAppointment.hour}</span>
            </Day>
            <Date>
              {getFormattedDay(selectedAppointment.vaccinationStartDate)}
            </Date>
          </Item>
        </SuccessContainer>
        {selectedCampaign.type === 'COVID' && (
          <TermLink
            href="http://vacinacao-docs.s3-website-us-east-1.amazonaws.com/TERMO_vacina.pdf"
            download
            target="_blank"
            rel="noreferrer"
          >
            Termo de Consentimento
          </TermLink>
        )}
        <NextButton
          onClick={handleNextStep}
          disabled={isButtonDisabled}
        >
          {chooseButtonText()}
        </NextButton>
      </Fragment>
    );
  };

  const renderUnavailableScreen = () => {
    const today = isToday(new window.Date(
      selectedAppointment.vaccinationStartDate
    ));

    return (
      <Fragment>
        <SuccessContainer>
          <Title>
            Ocorreu um erro
          </Title>
          <SuccessIconContainer>
            <SuccessIcon
              src={warning}
            />
          </SuccessIconContainer>
          <Message>
            Este horário não está mais disponível
          </Message>
          <Location>
            <LocationTime>
              {selectedAppointment.vaccinationLocationName}
            </LocationTime>
            <LocationLabel>
              {selectedAppointment.vaccinationLocationAddress}
            </LocationLabel>
          </Location>
          <Item
            style={{
              width: '80%',
              margin: '1rem auto',
              border: '2px solid #508CF0',
              cursor: 'initial'
            }}
          >
            <Day>
              {today
                ? 'Hoje'
                : chooseDay(selectedAppointment.vaccinationStartDate)
              }
            </Day>
            <Date>
              {selectedAppointment.hour}
            </Date>
          </Item>
        </SuccessContainer>
        <NextButton
          onClick={handleNextStep}
          disabled={isButtonDisabled}
        >
          {chooseButtonText()}
        </NextButton>
      </Fragment>
    );
  };

  const renderScreen = () => {
    switch (step) {
      case 'date':
        return renderDateScreen();
      case 'time':
        return renderTimeScreen();
      case 'summary':
        return renderSummaryScreen();
      case 'success':
        return renderSuccessScreen();
      case 'unavailable':
        return renderUnavailableScreen();
      default:
        return null;
    }
  };

  const renderMessage = () => {
    if (step === 'summary') {
      return (
        <Message>
          Confirme seu agendamento
        </Message>
      )
    }

    if (step === 'time') {
      return (
        <Message>
          Selecione uma data para seu atendimento
        </Message>
      )
    }

    if (!isLoadingDates && values(dates).length === 0) {
      return (
        <Message>
          Você está dentro dos critérios para vacinação, porém no momento estamos com todas as agendas de vacinação reservadas, verifique mais tarde por novas disponibilidades.
        </Message>
      )
    }
  }

  return (
    <Container
      isSelected={selectedDate.day}
    >
      <UserHeader />
      <Inner>
        <Header
          hasBack={step === 'time' || step === 'summary'}
          currentStep={step}
          color='#1B4487'
          destination={step === 'success' ? 'home' : 'screening'}
          setStep={setStep}
          setScreen={setParentScreen}
        />
        {(step !== 'success' && step !== 'unavailable') && (
          <Title>
            Agendar Vacinação
          </Title>
        )}
        {renderMessage()}
        {isLoadingDates
          ? <Spinner src={spinner} style={{ marginTop: '2rem' }} />
          : renderScreen()
        }
        {isLoading && (
          <Spinner src={spinner} />
        )}
      </Inner>
    </Container>
  );
};

export default Appointment;