// Libs
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
	isToday,
	getDay,
} from 'date-fns';
import QrReader from 'react-qr-reader';

// Assets
import spinner from '../../assets/spinner-white.svg';
import close from '../../assets/close.svg';
import closeWhite from '../../assets/close-white.svg';
import arrow from '../../assets/arrow.svg';
import success from '../../assets/success-white.svg';

// Animations
const TransitionButton = keyframes`
  0% { bottom: -77px };
  100% { bottom: 0 };
`;

// Styles
const Overlay = styled.div`
  position fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
	align-items: center;
	flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF72;
  backdrop-filter: blur(16px);
  z-index: 3;
`;

const Card = styled.div`
  position: relative;
	width: 95%;
	max-width: 500px;
  margin: 0 2.5%;
  padding: .75rem 8%;
  border-radius: 5px;
	background: #508CF0;
	padding-bottom: 70px;
`;

const Title = styled.h4`
  width: 100%;
  margin: 0;
  text-align: center;
  color: #fff;
  font: 400 1.25rem 'Open Sans', sans-serif;
`;

const Message = styled.span`
  display: block;
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  color: #FFE4D6;
  font: 400 .85rem 'Open Sans', sans-serif;
`;

const List = styled.ul`
  width: 100%;
  list-style: none;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff4d;
`;

const Day = styled.span`
  color: #fff;
  font: 400 1.25rem 'Open Sans', sans-serif;

  span {
    display: inline-block;
    margin-left: .5rem;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Location = styled.span`
  display: inline-block;
  width: 45%;
  color: #fff;
  font: 700 .8rem 'Open Sans', sans-serif;
`;

const FullButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #FFF;
  outline: none;
  cursor: pointer;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background: ${props => props.backgroundColor};
`;

const Reference = styled.span`
  display: inline-block;
  width: 35%;
  color: #fff;
  text-align: right;
  font: 700 .6rem 'Open Sans', sans-serif;
`;

const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, .2);
  cursor: pointer;
`;

const ItemText = styled.span`
  width: 90%;
  text-align: center;
  color: #FFFFFF;
  font: 400 .9rem 'Open Sans', sans-serif;
`;

const ReactionContainer = styled.div`
	width: 85%;
	max-width: 500px;
	max-height: 70vh;
`;

const ReactionHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	height: 110px;
	padding: 1rem;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: #508CF0;
`;

const ReactionTitle = styled.span`
	display: block;
	width: 80%;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	font: 500 1.2rem 'Open Sans', sans-serif;
`;

const ReactionDescription = styled.span`
	display: block;
	width: 70%;
	margin: 0 auto;
	color: #FFE4D6;
	text-align: center;
	font: 400 .75rem 'Open Sans', sans-serif;
`;

const ReactionClose = styled.img`
  position : absolute;
  right: 4%;
	top: 10%;
	cursor: pointer;
  width: 12px;
  height: 12px;
`;

const ReactionList = styled.ul`
	width: 100%;
	max-height: 50vh;
	margin: 0;
	padding: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	list-style: none;
	overflow-y: scroll;
	background-color: #508CF0;
`;

const ReactionItem = styled.li`
	display: flex;
	align-items: center;
	width: 100%;
	height: 65px;
	padding-left: 10%;
	color: #fff;
	cursor: pointer;
	font: 400 .75rem 'Open Sans', sans-serif;
	background-color: ${props => props.selected ? 'rgba(255,255,255, .15)' : 'rgba(0,0,0, .15)'};

	&:not(:last-child) {
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
`;

const ReactionTextContainer = styled.div`
	width: 100%;
	marign: 1rem 0;
	padding-bottom: .5rem;
	background: #508CF0;
`;

const ReactionTextArea = styled.textarea`
	display: block;
	width: 95%;
	margin: 0 auto;
	padding-top: 1rem;
	padding-left: 5%;
	border-radius: 4px;
	resize: none;
	outline: none;
	color: #1A2C48;
	font: 400 .8rem 'Open Sans', sans-serif;
	background: rgba(255, 255, 255, 0.88);
`;

const ReactionSuccessContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	max-width: 500px;
	margin: 0 auto;
	padding: 2rem 1rem;
	border-radius: 4px;
	background: #508CF0 0% 0% no-repeat padding-box;
`;

const SuccessIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: 2.5rem auto;
	border-radius: 50%;
	backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, .1);
`;

const SuccessIcon = styled.img`
  width: 40px;
	height: 40px;
`;

const SuccessInfo = styled.div``;

const ReactionThanks = styled.span`
	display: block;
	width: 100%;
	text-align: center;
	color: #FFE4D6;
	font: 400 .9rem 'Open Sans', sans-serif;
	font-style: italic;
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const CloseIcon = styled.img`
  position : absolute;
  right: 4%;
  top: 6%;
  width: 12px;
	height: 12px;
	cursor: pointer;
`;

const QRContainer = styled.div`
	position: relative;
	width: 80%;
	max-width: 550px;
	margin: 0 auto;
	padding: 1rem;
	border-radius: 5px;
	background-color: #508CF0;
`;

const QRTitle = styled.h4`
	margin: 0 0 1rem 0;
	color: #FFF;
	text-align: center;
	font: 700 .95rem 'Open Sans', sans-serif;
`;

const QRMessage = styled.span`
	display: block;
	width: 100%;
	margin: 1rem 0;
	color: #FFF;
	text-align: center;
	font: 400 .8rem 'Open Sans', sans-serif;
`;

const QRCamera = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 98%;
	height: 250px;
	margin: 1.5rem auto;
	border-radius: 5px;
	background-color: #FFF;
	overflow: hidden;

	${({ error }) => error && `
    border: 2px solid #B35B5C;
  `}

	@media(min-width: 768px) {
		height: 350px
	}
`;

const QrError = styled.span`
  display: block;
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  color: #B35B5C;
  font: 700 1rem 'Open Sans', sans-serif;
`;

const QRClose = styled.img`
  position : absolute;
  right: 3%;
  top: 3%;
  width: 12px;
	height: 12px;
	cursor: pointer;
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 77px;
  padding: 0 5%;
  border: none;
  cursor: pointer;
  outline: none;
  color: #1B4487;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background-color: #fff;
  animation: ${TransitionButton} .5s;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    background: url(${arrow}) no-repeat center center;
    background-size: contain;
  }

  @media (min-width: 768px) {
    position: static;
		width: 80%;
		max-width: 500px;
    margin: 1rem auto 0;
    height: 50px;
    border-radius: 4px;
    animation: none;
  }
`;

const options = [
	{
		id: 1,
		text: 'Já fui vacinado contra a [] em outro estabelecimento'
	},
	{
		id: 2,
		text: 'Possuo contraindicação à vacina ou a um de seus componentes'
	},
	{
		id: 3,
		text: 'Estou apresentando problemas respiratórios e/ou diagnóstico ativo de [] (mesmo que assintomático) há menos de duas semanas completas'
	},
	{
		id: 4,
		text: 'Não fui vacinado e me oponho a ser vacinado contra a []'
	},
	{
		id: 5,
		text: 'Não possuo disponibilidade de horário'
	}
];

const reactionProblems = [
	{ id: 1, text: 'Não impedem minhas atividades' },
	{ id: 2, text: 'Atrapalham minha atividade diária' },
	{ id: 3, text: 'Foi necessário atendimento médico' },
	{ id: 4, text: 'Foi necessário internação' },
];

const getFormattedDay = (date) => {
	var day = date
		.split('T')[0]
		.split('-');

	return `${day[2]}/${day[1]}/${day[0]}`;
};

const getFormattedHour = (date) => {
	var hrs = date
		.split('T')[1]
		.split(':');

	return `${hrs[0]}:${hrs[1]}`;
};

const Modal = ({
	modal,
	selectedAppointment,
	symptoms,
	isLoading,
	isLoadingReaction,
	setModal,
	handleQuestionaire,
	cancelAppointment,
	informReactions,
	setLoadingConfirmation,
	sendVaccineManufacturer,
}) => {
	const [selectedReactions, setReactions] = useState([]);
	const [selectedProblem, setProblem] = useState('');
	const [description, setDescription] = useState('');
	const [isLoadingQR, setLoadingQR] = useState(false);
	const [error, setError] = useState('');


	const sendQRInfo = async (info) => {
		setError('');
		setLoadingQR(true);
		// parse & post QR CODE here...
		const fields = {};

		if (info.includes('sirio-covid-19-campaign')) {
			const container = document.createElement('div');
			container.innerHTML = info;


			for (let i = 1; i < container.children.length; i++) {
				const el = container.children[i];
				fields[el.tagName.toLocaleLowerCase()] = el.innerHTML;
			}

			sendVaccineManufacturer(fields.fabricante, fields.lote);
		} else {
			setError('Qr code inválido!');
			setLoadingQR(false);
		}
	};

	const chooseDay = (date) => {
		const day = getDay(new window.Date(date));

		switch (day) {
			case 0:
				return 'Dom';
			case 1:
				return 'Seg';
			case 2:
				return 'Ter';
			case 3:
				return 'Qua';
			case 4:
				return 'Qui';
			case 5:
				return 'Sexta';
			case 6:
				return 'Sab';
			default:
				return '';
		}
	};

	const handleReactions = () => {
		informReactions(selectedReactions.map(item => item.id), selectedProblem, description);
	};

	const renderMotives = () => {
		return options.map((item) => (
			<Item
				key={item.id}
				onClick={() => {
					handleQuestionaire(item.id);
				}}
			>
				<ItemText>{item.text.replace('[]', modal.campaign.type)}</ItemText>
			</Item>
		))
	}

	const renderReactions = () => {
		return symptoms.map((item) => (
			<ReactionItem
				key={item.id}
				selected={selectedReactions.find(r => r.id === item.id)}
				onClick={() => {
					const alreadyAdded = selectedReactions.find(r => r.id === item.id);

					if (alreadyAdded) {
						setReactions(selectedReactions
							.filter(reaction => reaction.id !== item.id)
						);
					} else {
						setReactions(
							selectedReactions.concat(item)
						);
					}
				}}
			>
				{item.description}
			</ReactionItem>
		));
	}

	const renderReactionProblems = () => {
		return reactionProblems.map((item) => (
			<ReactionItem
				key={item.id}
				selected={selectedProblem === item.text}
				onClick={() => {
					setProblem(item.text)
				}}
			>
				{item.text}
			</ReactionItem>
		));
	}

	if (modal.type === 'cancel') {
		const today = isToday(new window.Date(selectedAppointment.vaccinationStartDate));

		return (
			<Overlay
				onClick={() => {
					setModal({});
				}}
			>
				<Card
					onClick={(e) => e.stopPropagation()}
				>
					<Title>
						Cancelar Agendamento
          </Title>
					<Message>
						Tem certeza que deseja cancelar seu agendamento de vacinação?
          </Message>
					<Info>
						<Day>
							{today ? 'Hoje' : chooseDay(selectedAppointment.vaccinationStartDate)}
							<span>{getFormattedHour(selectedAppointment.vaccinationStartDate)}</span>
						</Day>
						<Day>
							{getFormattedDay(selectedAppointment.vaccinationStartDate)}
						</Day>
					</Info>
					<LocationContainer>
						<Location>
							{selectedAppointment.vaccinationLocationName}
						</Location>
						<Reference>
							{selectedAppointment.vaccinationLocationAddress}
						</Reference>
					</LocationContainer>
					<FullButton
						onClick={() => cancelAppointment()}
						backgroundColor="#00000033"
						disabled={isLoading}
					>
						{isLoading
							? <Spinner src={spinner} />
							: 'Sim, cancelar'
						}
					</FullButton>
					<CloseIcon
						src={close}
						onClick={() => {
							setModal({});
						}}
					/>
				</Card>
			</Overlay>
		)
	}

	if (modal.type === 'cancel-success') {
		return (
			<Overlay
				onClick={() => {
					setModal({});
				}}
			>
				<Card
					onClick={(e) => e.stopPropagation()}
					style={{
						padding: '1rem 0',
					}}
				>
					<Title>
						Agendamento Cancelado
          </Title>
					<Message
						style={{
							width: '80%',
							margin: '1rem auto',
							fontSize: '.8rem'
						}}
					>
						Por favor, selecione o motivo que o fez desistir de seu agendamento
          </Message>
					<List
						style={{
							marginTop: '.75rem'
						}}
					>
						{renderMotives()}
					</List>
				</Card>
			</Overlay>
		);
	}

	if (modal.type === 'reaction') {
		return (
			<Overlay
				onClick={() => {
					setModal({});
				}}
				isReaction
			>
				<ReactionContainer
					onClick={(e) => e.stopPropagation()}
				>
					<ReactionHeader>
						<ReactionTitle>
							Reportar Reação
						</ReactionTitle>
						<ReactionDescription>
							Selecione quais reações estão ocorrendo
						</ReactionDescription>
						<ReactionClose
							src={closeWhite}
							onClick={() => {
								setModal({});
							}}
						/>
					</ReactionHeader>
					<ReactionList>
						{renderReactions()}
					</ReactionList>
				</ReactionContainer>
				{selectedReactions.length > 0 && (
					<NextButton
						onClick={(ev) => {
							ev.stopPropagation();

							if (selectedReactions.find(item => item.hasAdditionalInfo)) {
								setModal({
									type: 'reaction-description',
									isOpen: true,
									campaign: modal.campaign
								});
							} else {
								setModal({
									type: 'reaction-problems',
									isOpen: true,
									campaign: modal.campaign
								});
							}
						}}
					>
						Avançar
					</NextButton>
				)}
			</Overlay>
		)
	}

	if (modal.type === 'reaction-problems') {
		return (
			<Overlay
				isReaction
				onClick={() => {
					setModal({});
				}}
			>
				<ReactionContainer
					onClick={(e) => e.stopPropagation()}
				>
					<ReactionHeader>
						<ReactionTitle>
							Relatar Sintoma
						</ReactionTitle>
						<ReactionDescription>
							Quanto a severidade das ocorrências selecione:
						</ReactionDescription>
						<ReactionClose
							src={closeWhite}
							onClick={() => {
								setModal({});
							}}
						/>
					</ReactionHeader>
					<ReactionList>
						{renderReactionProblems()}
					</ReactionList>
				</ReactionContainer>
				{selectedProblem.length > 0 && (
					<NextButton
						disabled={isLoadingReaction}
						onClick={(ev) => {
							ev.stopPropagation();

							handleReactions();
						}}
					>
						{isLoadingReaction
							? 'Enviando...'
							: 'Enviar para equipe médica'
						}
					</NextButton>
				)}
			</Overlay>
		)
	}

	if (modal.type === 'reaction-description') {
		return (
			<Overlay
				isReaction
				onClick={() => {
					setModal({});
				}}
			>
				<ReactionContainer
					onClick={(e) => e.stopPropagation()}
				>
					<ReactionHeader>
						<ReactionTitle>
							Relatar Sintoma
						</ReactionTitle>
						<ReactionDescription>
							Diga em poucas palavras o que você está sentindo:
						</ReactionDescription>
						<ReactionClose
							src={closeWhite}
							onClick={() => {
								setModal({});
							}}
						/>
					</ReactionHeader>
					<ReactionTextContainer>
						<ReactionTextArea
							placeholder="Digite aqui o que você está sentindo..."
							rows={14}
							spellCheck={false}
							onChange={(ev) => {
								setDescription(ev.target.value);
							}}
						/>
					</ReactionTextContainer>
				</ReactionContainer>
				{description.length > 4 && (
					<NextButton
						onClick={(ev) => {
							ev.stopPropagation();

							setModal({
								type: 'reaction-problems',
								isOpen: true,
								campaign: modal.campaign
							});
						}}
						disabled={isLoadingReaction}
					>
						Avançar
					</NextButton>
				)}
			</Overlay>
		)
	}

	if (modal.type === 'reaction-success') {
		return (
			<Overlay
				isReaction
				onClick={() => {
					setModal({});
				}}
			>
				<ReactionSuccessContainer>
					<ReactionTitle>
						Sintoma Relatado com Sucesso
					</ReactionTitle>
					<SuccessIconContainer>
						<SuccessIcon src={success} />
					</SuccessIconContainer>
					<SuccessInfo>
						<ReactionThanks>
							Obrigado pela informação
						</ReactionThanks>
						<ReactionDescription
							style={{
								marginTop: '1rem',
								color: '#FFFFFF'
							}}
						>
							Logo um profissional do time do Cuidando de quem cuida entrará em contato com você para realizar o atendimento
						</ReactionDescription>
					</SuccessInfo>
					<ReactionClose
						src={closeWhite}
						style={{
							top: '3%',
							right: '3%'
						}}
						onClick={() => {
							setModal({});
						}}
					/>
				</ReactionSuccessContainer>
			</Overlay>
		)
	}

	if (modal.type === 'qr-code') {
		return (
			<Overlay
				onClick={() => {
					setModal({})
				}}
			>
				<QRContainer
					onClick={(ev) => ev.stopPropagation()}
				>
					<QRTitle>
						QR Code
					</QRTitle>
					<QRMessage>
						Aponte a câmera do celular para o QR Code para finalizar a operação
					</QRMessage>
					<QRCamera
						error={error}
					>
						<QrReader
							delay={300}
							onError={(err) => {
								setError(typeof err);
							}}
							onScan={(scan) => {
								if (scan && !isLoadingQR) {
									sendQRInfo(scan);
								}
							}}
							style={{
								width: '100%',
							}}
							className="qr-container"
							facingMode="environment"
							showViewFinder={false}
							resolution={400}
						/>
					</QRCamera>
					{isLoadingQR && (
						<Spinner src={spinner} />
					)}
					{error && (
						<QrError>{error}</QrError>
					)}
					<QRClose
						src={closeWhite}
						onClick={() => {
							setLoadingConfirmation(false);
							setModal({});
						}}
					/>
				</QRContainer>
			</Overlay>
		)
	}

	return null;
};

export default Modal;