import React, { Fragment } from 'react';
import styled from 'styled-components';

// Assets
import arrow from '../assets/arrow.svg';
import spinner from '../assets/spinner.svg';

const CampaingList = styled.ul`
  width: 90%;
  margin: 0 auto;
  max-width: 500px;
  padding: 0;
`;

const CampaignListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 500px;
  height: 80px;
  margin: 2rem auto 0;
  margin-bottom: .15rem;
  border-radius: 5px 5px 0 0;
  background-color: #fff;

  ${({ onlyHeader }) => onlyHeader && `
    border-radius: 5px;
  `}

  @media(min-width: 766px) {
    margin-top: 5rem;
  }
`;

const CampaingItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin: 0;
  margin-bottom: .1rem;
  background-color: #fff;
`;

const CampaignName = styled.span`
  color: #1B4487;
  font: 700 1.1rem 'Open Sans', sans-serif;
`;

const CampaingLabel = styled.span`
  color: #1B4487;
  font: 400 .95rem 'Open Sans', sans-serif;
`;

const Arrow = styled.img`
  position: absolute;
  top: 50%;
  right: 5%;
  width: 20px;

  transform: translateY(-50%);
`;

const Spinner = styled.img`
  position: absolute;
  top: 50%;
  right: 5%;
  width: 40px;

  transform: translateY(-50%);
`;

const CampaignsList = ({
  campaigns,
  appointments,
  campaignLoader,
  checkAppointment,
  onlyHeader,
  setScreen
}) => {
  const activeCampaigns = campaigns.filter(campaign => {
    return !appointments.find(
      appointment => (appointment.type === campaign.type) && (appointment.vaccineDose === campaign.vaccineDose)
    );
  });

  const renderCampaigns = () => {
    return activeCampaigns.map((campaign) => (
      <CampaingItem
        key={campaign.id}
        onClick={() => {
          if (campaignLoader) return;
          checkAppointment(campaign);
        }}
        disabled={campaignLoader && (campaignLoader !== campaign.id)}
      >
        {campaign.vaccineDose === 0 && (
          <CampaignName>
            {campaign.type} - Dose única
          </CampaignName>
        )}
        {campaign.vaccineDose > 0 && (
          <CampaignName>
            {campaign.type} - {campaign.vaccineDose}ª Dose
          </CampaignName>
        )}
        {campaignLoader === campaign.id
          ? <Spinner src={spinner} />
          : <Arrow src={arrow} />
        }
      </CampaingItem>
    ))
  }

  if (activeCampaigns.length === 0) return null;

  return (
    <Fragment>
      <CampaignListHeader
        onlyHeader={onlyHeader}
        onClick={() => {
          if (onlyHeader) setScreen('campaigns');
        }}
      >
        <CampaignName>
          Campanhas em andamento
        </CampaignName>
        <CampaingLabel>
          Clique para agendar
        </CampaingLabel>
      </CampaignListHeader>
      {!onlyHeader && (
        <CampaingList>
          {renderCampaigns()}
        </CampaingList>
      )}
    </Fragment>
  );
};

export default CampaignsList;