import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';

// Private HOC
import Private from './private';

// Routes
import Layouts from './pages/Layouts/Layouts';
import Login from './pages/Login/Login';
import Validation from './pages/Validation/Validation';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path='/login'
          render={(props) => (
            <Login {...props} />
          )}
        />
        <Route
          exact
          path='/validation'
          render={(props) => (
            <Validation {...props} />
          )}
        />
        <Private
          render={(props) => (
            <Layouts {...props} />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
