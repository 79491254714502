// Libs
import React from 'react';
import styled from 'styled-components';

// assets
import logo from '../assets/logo.png';

const Container = styled.header`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 860px;
  height: 120px;
  padding: 0 9%;
  margin: 0 auto;
  background-color: #1B4487;
`;

const Title = styled.span`
  width: 35%;
  display: inline-block;
  color: #fff;
  font: 400 1.1rem 'Open Sans', sans-serif;
`;

const Logo = styled.img`
  width: 150px;
`;

const Line = styled.div`
  width: 1px;
  height: 70%;
  background-color: #FFFFFF1A;
`;

const Header = ({
  hasClose,
  destination,
  setScreen
}) => {
  return (
    <Container>
      <Logo src={logo} />
      <Line />
      <Title>
        Campanha de Vacinação
      </Title>
    </Container>
  );
};

export default Header;