// Libs
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import {
	isToday,
	getDay,
} from 'date-fns';

// Assets
import success from '../../assets/success-white.svg';
import download from '../../assets/download.svg';
import spinner from '../../assets/spinner-white.svg';

// Styles
const MainContainer = styled.div`
	flex-shrink: 0;
	position: relative;
	width: 100%;
	margin: ${props => props.isMobile && '0 10px'};
	scroll-snap-align: start;
`;

const ConfirmBox = styled.div`
  width: 100%;
  margin: 0 auto 1rem;
`;

const ConfirmTitle = styled.button`
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 5px;
  color: #FFF;
  text-align: center;
  outline: none;
  cursor: pointer;
  font: 400 1.2rem 'Open Sans', sans-serif;
  background-color: #508CF0;

  ${({ confirmActive }) => confirmActive && `
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  `}
`;

const ConfirmMessage = styled.button`
  display: block;
  width: 100%;
  height: 47px;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  text-align: center;
  outline: none;
  cursor: pointer;
  font: 400 1rem 'Open Sans', sans-serif;
  background: #4975BE;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	position: relative;
  width: 100%;
	height: 385px;
  padding: 0.75rem .75rem 0;
  border-radius: 5px;
  background: #508CF0;
`;

const CardName = styled.span`
	display: flex;
	flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  height: 50px;
	margin: .5rem auto 1rem;
	padding: .25rem 0;
	border-radius: 15px;
  color: #fff;
  font: 700 .85rem 'Open Sans', sans-serif;
	background: #FFFFFF1A;
	
	span {
		font: 400 .8rem 'Open Sans', sans-serif;
	}
`;

const ManufacturerLabel = styled.span`
	display: inline-block;
	width: 100%;
	margin: .25rem auto;
  color: #fff;
	text-align: center;
  font: 700 .95rem 'Open Sans', sans-serif;
`;

const CardTitle = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
  margin: 0;
  text-align: center;
  color: #fff;
  font: 700 1.5rem 'Open Sans', sans-serif;

	span {
		display: inline-block;
		margin-left: .5rem;
		padding: .25rem .5rem;
		border-radius: 12px;
		color: #1B4487;
		font: 700 .7rem 'Open Sans', sans-serif;
		background-color: rgba(255, 255, 255, 0.6);
	}
`;

const CardMessage = styled.span`
  display: block;
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  color: #FFE4D6;
  font: 400 .85rem 'Open Sans', sans-serif;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ffffff4d;
`;

const Day = styled.span`
  color: #fff;
  font: 400 1.25rem 'Open Sans', sans-serif;

  span {
    display: inline-block;
    margin-left: .5rem;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Location = styled.span`
  display: inline-block;
  width: 45%;
  color: #fff;
  font: 700 .8rem 'Open Sans', sans-serif;
`;

const Reference = styled.span`
  display: inline-block;
  width: 35%;
  color: #fff;
  text-align: right;
  font: 700 .6rem 'Open Sans', sans-serif;
`;

const SuccessIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: .75rem auto;
  border-radius: 50%;
  background-color: #8198BD4d;
`;

const SuccessIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const Message = styled.span`
  display: block;
  width: 80%;
  margin: 1rem auto;
  text-align: center;
  color: #FFE4D6;
  font: 300 .8rem 'Open Sans', sans-serif;
`;

const CardCancelMessage = styled.span`
  display: inline-block;
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  color: #FFF;
  font: 400 .75rem 'Open Sans', sans-serif;
`;

const CancelHour = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 2rem auto 1rem;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #D561614d;
    transform: translateY(-50%);
  }

  @media (min-width: 768px) {
    width: 65%
  }
`;

const WarningMessage = styled.span`
  position: relative;
  display: block;
  width: 80%;
  margin: 2.5rem auto 0;
  text-align: center;
  color: #1B4487;
  font: 500 .9rem 'Open Sans', sans-serif;
  z-index: 1;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 47px;
  border: none;
  color: #FFF;
  outline: none;
  cursor: pointer;
  font: 400 .9rem 'Open Sans', sans-serif;
  background: ${props => props.backgroundColor};
`;

const FullButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  border: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #FFF;
  outline: none;
  cursor: pointer;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background: ${props => props.backgroundColor};
`;

const TermLink = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 50px;
  margin: 1.5rem auto;
  padding: 0 5%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #1B4487;
  font: 400 .95rem 'Open Sans', sans-serif;
  background-color: #fff;
  z-index: 1;

  &::after {
    content: '';
    width: 25px;
    height: 25px;
    background: url(${download}) no-repeat center center;
    background-size: contain;
  }
`;

const ReactionButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	height: 60px;
	margin: 0 auto 1rem;
	border: none;
	border-radius: 4px;
	color: #1B4487;
	outline: none;
	cursor: pointer;
	font: 700 .95rem 'Open Sans', sans-serif;
	background: #fff;

	span {
		display: block;
		width: 100%;
		font: 400 .6rem 'Open Sans', sans-serif;
	}
`;

const ReactionFeedback = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80%;
	height: 26px;
	margin: 1rem auto 0;
	border-radius: 12px;
	color: #FFF;
	text-transform: uppercase;
	font: 400 1rem 'Open Sans', sans-serif;
	background: #FFFFFF1A;
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const getFormattedDay = (date) => {
	var day = date
		.split('T')[0]
		.split('-');

	return `${day[2]}/${day[1]}/${day[0]}`;
};

const getFormattedHour = (date) => {
	var hrs = date
		.split('T')[1]
		.split(':');

	return `${hrs[0]}:${hrs[1]}`;
};

const chooseDay = (date) => {
	const day = getDay(new window.Date(date));

	switch (day) {
		case 0:
			return 'Dom';
		case 1:
			return 'Seg';
		case 2:
			return 'Ter';
		case 3:
			return 'Qua';
		case 4:
			return 'Qui';
		case 5:
			return 'Sexta';
		case 6:
			return 'Sab';
		default:
			return '';
	}
};

const Card = ({
	appointment,
	index,
	name,
	deviceType,
	isLoadingConfirmation,
	confirmActive,
	campaigns,
	setModal,
	setConfirmActive,
	setAppointment,
	remarkAppointment,
	confirmAppointment,
}) => {
	useEffect(() => {
		const observer = new IntersectionObserver(function (entries) {
			if (entries[0].isIntersecting) {
				setAppointment(appointment);
			}
		}, {
			threshold: [0.5],
		});

		observer.observe(document.querySelector(`#vaccine-dose-${appointment.id}`));
	}, [])

	const renderManufacturer = (appointment) => {
		if (appointment.manufacturer && !appointment.lot) {
			return (
				<ManufacturerLabel>
					{appointment.manufacturer}
				</ManufacturerLabel>
			)
		}

		if (appointment.manufacturer && appointment.lot) {
			return (
				<ManufacturerLabel>
					{`${appointment.manufacturer} - Lote ${appointment.lot}`}
				</ManufacturerLabel>
			)
		}

		return null;
	}

	const renderContent = () => {
		const today = isToday(new window.Date(appointment.vaccinationStartDate));
		if (appointment.status === 'Agendado') {
			return (
				<Fragment>
					<ConfirmBox>
						<ConfirmTitle
							onClick={() => setConfirmActive(true)}
							confirmActive={confirmActive}
						>
							Já se vacinou?
            </ConfirmTitle>
						{confirmActive && (
							<ConfirmMessage
								onClick={() => {
									confirmAppointment(appointment);
								}}
							>
								{isLoadingConfirmation
									? <Spinner src={spinner} style={{ width: '40px', height: '40px' }} />
									: 'Sim, me vacinei'
								}
							</ConfirmMessage>
						)}
					</ConfirmBox>
					<Container
						id={`vaccine-dose-${appointment.vaccineDose}`}
						key={`${appointment.status}-${index}`}
						style={{
							paddingBottom: '70px'
						}}
					>
						<CardTitle>
							{appointment.type}
							<span>{appointment.vaccineDose}ª dose</span>
						</CardTitle>
						<CardName>
							{name}
						</CardName>
						{renderManufacturer(appointment)}
						<CardMessage>
							{`Você receberá imunização contra ${appointment.type}`}
						</CardMessage>
						<Info>
							<Day>
								{today ? 'Hoje' : chooseDay(appointment.vaccinationStartDate)},
							<span>
									{getFormattedHour(appointment.vaccinationStartDate)}
								</span>
							</Day>
							<Day>
								{getFormattedDay(appointment.vaccinationStartDate)}
							</Day>
						</Info>
						<LocationContainer>
							<Location>
								{appointment.vaccinationLocationName}
							</Location>
							<Reference>
								{appointment.vaccinationLocationAddress}
							</Reference>
						</LocationContainer>
						<ButtonsContainer>
							<Button
								backgroundColor="rgba(0,0,0, 0.1)"
								onClick={() => {
									const selectedCampaign = campaigns.find(item => item.type === appointment.type);

									setModal({
										type: 'cancel',
										isOpen: true,
										campaign: selectedCampaign
									});
								}}
								disabled={isLoadingConfirmation}
							>
								Cancelar
						</Button>
							<Button
								backgroundColor="rgba(0,0,0, 0.15)"
								onClick={() => {
									remarkAppointment(appointment);
								}}
								disabled={isLoadingConfirmation}
							>
								Reagendar
						</Button>
						</ButtonsContainer>
					</Container>
					{/* appointment.type === 'COVID-19' && (
						<TermLink
							href="http://vacinacao-docs.s3-website-us-east-1.amazonaws.com/TERMO_vacina.pdf"
							download
							target="_blank"
							rel="noreferrer"
						>
							Termo de Consentimento
						</TermLink>
					) */}
				</Fragment>
			);
		}

		if (appointment.status === 'Cancelado') {
			return (
				<Container
					style={{
						paddingBottom: '70px'
					}}
				>
					<CardTitle
						style={{
							textDecoration: 'line-through',
							textDecorationColor: '#D56161'
						}}
					>
						{appointment.type}
						<span>{appointment.vaccineDose}ª dose</span>
					</CardTitle>
					<CardName>
						{name}
					</CardName>
					{renderManufacturer(appointment)}
					<CancelHour>
						<Day
							style={{
								opacity: '0.3'
							}}
						>
							{today ? 'Hoje' : chooseDay(appointment.vaccinationStartDate)},
            <span>
								{getFormattedHour(appointment.vaccinationStartDate)}
							</span>
						</Day>
						<Day
							style={{
								opacity: '0.3'
							}}
						>
							{getFormattedDay(appointment.vaccinationStartDate)}
						</Day>
					</CancelHour>
					<CardCancelMessage>
						{`Você pode agendar um novo horário para vacinação contra ${appointment.type}`}
					</CardCancelMessage>
					<FullButton
						backgroundColor="#3f70c2"
						onClick={() => {
							remarkAppointment(appointment);
						}}
					>
						Reagendar
        </FullButton>
				</Container>
			);
		}

		if (appointment.status === 'Realizado') {
			return (
				<Fragment>
					{!appointment.hasVaccineReaction && (
						<ReactionButton
							onClick={() => {
								const selectedCampaign = campaigns.find(item => item.type === appointment.type);
								setModal({
									type: 'reaction',
									isOpen: true,
									campaign: selectedCampaign
								});
							}}
						>
							Reportar Reação à Vacina
						</ReactionButton>
					)}
					<Container
						style={{ paddingBottom: '10px' }}
					>
						<CardTitle>
							{appointment.type}
							<span>{appointment.vaccineDose}ª dose</span>
						</CardTitle>
						{appointment.hasVaccineReaction && (
							<ReactionFeedback>
								Sintoma Relatado
							</ReactionFeedback>
						)}
						<SuccessIconContainer>
							<SuccessIcon src={success} />
						</SuccessIconContainer>
						<CardName>
							{name}
							{renderManufacturer(appointment)}
						</CardName>
						<Info>
							<Day>
								{today ? 'Hoje' : chooseDay(appointment.vaccinationStartDate)},
            <span>
									{getFormattedHour(appointment.vaccinationStartDate)}
								</span>
							</Day>
							<Day>
								{getFormattedDay(appointment.vaccinationStartDate)}
							</Day>
						</Info>
						<LocationContainer>
							<Location>
								{appointment.vaccinationLocationName}
							</Location>
							<Reference>
								{appointment.vaccinationLocationAddress}
							</Reference>
						</LocationContainer>
					</Container>
				</Fragment>
			);
		}
	}

	return (
		<MainContainer
			id={`vaccine-dose-${appointment.id}`}
			isMobile={deviceType === 'mobile'}
		>
			{renderContent()}
		</MainContainer>
	);
};

export default Card;