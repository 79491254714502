// Libs
import React, { Fragment, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Document, Page, View, Image, Text, pdf, StyleSheet } from '@react-pdf/renderer';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { getDay, getMonth } from 'date-fns';

// Assets
import logo from '../../assets/logo.png';
import arrow from '../../assets/arrow.svg';
import warning from '../../assets/warning.svg';
import spinner from '../../assets/spinner.svg';
import letterLogo from '../../assets/letter-logo.png'
import letterSignature from '../../assets/letter-signature.jpg';
import success from '../../assets/success.svg';

// Components
import Header from '../../components/Header';

// Utils
import { cpfMask, rgMask } from '../../utils/validation';

const pdfStyles = StyleSheet.create({
  title: {
    marginBottom: 10,
    textAlign: 'center',
    fontWeight: 700
  },
  paragraph: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 5,
    fontSize: 12,
    lineHeight: 1.5
  },
  smallParagraph: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 15,
    fontSize: 11,
    lineHeight: 1
  },
  instructions: {
    marginBottom: 20
  },
  signature: {
    marginTop: 20,
  },
  footer: {
    position: 'absolute',
    top: '107%',
    left: '0',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    marginTop: 15,
    fontSize: 11,
    lineHeight: 1
  }
});

// Animations
const TransitionButton = keyframes`
  0% { bottom: -77px };
  100% { bottom: 0 };
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding-bottom: ${props => props.selected ? '77px' : '1rem'};
  background: #1B4487;
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Title = styled.h1`
  width: 80%;
  margin: 2.5rem auto 2rem;
  text-align: center;
  color: #fff;
  font: 400 1.5rem 'Open Sans', sans-serif;
`;

const Message = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #FFE4D6;
  font: 400 .9rem 'Open Sans', sans-serif;
`;

const List = styled.ul`
  position: relative;
  width: 80%;
  margin: 2rem auto 0;
  z-index: 2;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 95px;
  margin: .75rem 0;
  padding: 1rem 5%;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#508CF0' : '#FFFFFF4d'};
`;

const Paragraph = styled.span`
  color: #fff;
  font: 400 .8rem 'Open Sans', sans-serif;
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 77px;
  border: none;
  padding: 0 10%;
  cursor: pointer;
  outline: none;
  color: #1B4487;
  font: 400 1.125rem 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  animation: ${TransitionButton} .5s;
  z-index: 2;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    background: url(${arrow}) no-repeat center center;
    background-size: contain;
  }

  @media (min-width: 768px) {
    position: static;
    width: 80%;
    margin: 2rem auto;
    height: 50px;
    border-radius: 4px;
    animation: none;
  }
`;

const Logo = styled.img`
  display: block;
  width: 60%;
  margin: 3rem auto 0;
`;

const ConfirmBox = styled.div`
  width: 80%;
  margin: 20% auto 1rem;
  padding: .5rem 5%;
  border: 2px solid #1B4487;
  border-radius: 7px;
  background-color: #FFFFFF72;
`;

const ConfirmTitle = styled.h2`
  color: #1B4487;
  font: 400 1.4rem 'Open Sans', sans-serif;
`;

const ConfirmMessage = styled.span`
  display: block;
  width: 100%;
  margin: .5rem auto;
  color: #508CF0;
  font: 400 .95rem 'Open Sans', sans-serif;
  font-style: italic;
`;

const ConfirmButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 48px;
  margin: 1rem auto 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  color: #FFF;
  font: 400 1.1rem 'Open Sans', sans-serif;
  background-color: #1B4487;
`;

const WarningIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #8198BD4d;
`;

const WarningIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const WarningMessage = styled.p`
  width: 80%;
  margin: 2rem auto 0;
  text-align: center;
  color: #8198BD;
  font: 300 .95rem 'Open Sans', sans-serif;
  font-style: italic;

  a {
    color: #1B4487;
    tex-decoration: none;
    font-weight: 700;
  }
`;

const WarningBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 1.5rem auto;
  padding: .5rem;
  border-radius: 5px;
  background-color: #FFFFFF1A;

  p {
    display: block;
    width: 90%;
    margin: 0 auto;
    color: #fff;
    font: 500 .8rem 'Open Sans', sans-serif;
  }
`;

const WarningAdvice = styled.p`
  width: 70%;
  margin: 1rem auto;
  color: #fff;
  text-align: center;
  font: 600 .85rem 'Open Sans', sans-serif;
`;

const WarningEmail = styled.a`
  display: block;
  width: 100%;
  margin: .75rem 0;
  text-align: center;
  color: #508CF0;
  text-decoration: none;
  font: 700 .9rem 'Open Sans', sans-serif;
`;

const ChangeStatus = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem auto 0;
  color: #fff;
  font: 400 .95rem 'Open Sans', sans-serif;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;

  @media (min-width: 768px) {
    display: block;
    margin: 4rem auto 0;
  }
`;

const DownloadContainer = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 2.5rem auto 0;
  max-width: 550px;
  border-radius: 5px;
  background-color: #CEDFE7;
`;

const DownloadTitle = styled.h4`
  width: 100%;
  margin: 0;
  text-align: center;
  color: #1B4487;
  font: 400 1.1rem 'Open Sans', sans-serif;
`;

const DownloadName = styled.span`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  text-align: center;
  color: #1B4487;
  font: 700 .85rem 'Open Sans', sans-serif;
`;

const DownloadDescription = styled.span`
  display: block;
  width: 100%;
  margin-top: .5rem;
  text-align: center;
  color: #1B4487;
  font: 400 .85rem 'Open Sans', sans-serif;
`;

const DownloadMessage = styled.p`
  color: #1B4487;
  text-align: center;
  font: 400 .85rem 'Open Sans', sans-serif;
`;

const SuccessIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  margin: 2.5rem auto;
  border-radius: 50%;
  background-color: #8198BD4d;
`;

const SuccessIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const DownloadButton = styled.button`
  display: block;
  width: 90%;
  margin: 2rem auto;
  height: 24px;
  border: 1px solid #BCD0D9;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  color: #1B4487;
  font: 400 .8rem 'Open Sans', sans-serif;
  background: #BCD0D96E;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, .2);
`;

const Modal = styled.div`
  width: 95%;
  height: 80%;
  max-width: 550px;
  padding: 1rem 0;
  border-radius: 5px;
  background: #508CF0;
`;

const ModalTitle = styled.h3`
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font: 400 1.25rem 'Open Sans', sans-serif;
`;

const ModalDescription = styled.span`
  display: block;
  width: 90%;
  margin: 1rem auto;
  color: #FFE4D6;
  text-align: center;
  font: 400 .95rem 'Open Sans', sans-serif;
`;

const ModalList = styled.ul`
  width: 100%;
  height: calc(100% - 120px);
  padding: 0;
  border-top: .5px solid rgba(255, 255, 255, .2);
  list-style: none;
  overflow-y: scroll;
`;

const UnityItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: .5rem 0;
  border-bottom: .5px solid rgba(255, 255, 255, .2);
  color: #fff;
  font: 600 .9rem 'Open Sans', sans-serif;

  span {
    display: block;
    text-align: center;
    margin: .15rem 0;
  }
`;

const ModalButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  height: 60px;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font: 700 .9rem 'Open Sans', sans-serif;
  background: #4874BD;
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 1.5rem auto;
`;

const NavButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.full ? '100%' : '49%'};
  height: 55px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: #1B4487;
  font: 700 .9rem 'Open Sans', sans-serif;

  &::after {
    content: '';
    display: 'inline-block';
    position: absolute;
    top: 50%;
    width: 15px;
    height: 15px;
    background: url(${arrow}) no-repeat center center;
    background-size: contain;
    transform: translateY(-50%);

    ${({ next }) => next && `
      right: 5%;
    `}

    ${({ next }) => !next && `
      left: 5%;
      transform: translateY(-50%) rotate(-180deg);
    `}
  }
`;

const options = [
  {
    id: 5,
    text: 'Quero agendar uma data para vacinação. Não possuo contra-indicações.'
  },
  {
    id: 1,
    text: 'Já fui vacinado contra a [] em outro estabelecimento'
  },
  {
    id: 2,
    text: 'Possuo contra-indicação a vacina ou a um de seus componentes'
  },
  {
    id: 3,
    text: 'Estou apresentando sintomas de [] e/ou diagnóstico ativo de COVID-19 (mesmo que assintomáticos) há menos de duas semanas completas'
  },
  {
    id: 4,
    text: 'Não fui vacinado e me oponho a ser vacinado contra a []'
  },
  {
    id: 6,
    text: 'Faço parte do grupo de voluntários de estudo para o desenvolvimento da vacina contra a []'
  },
];

const unities = [
  {
    name: 'UBS HUMAITÁ - DR. JOÃO DE AZEVEDO LAGE',
    address: 'R. HUMAITÁ, 520 - BELA VISTA',
    cep: '01321-010 - FONE: 3241-1632 / 3241-1163'
  },
  {
    name: 'UBS NOSSA SENHORA DO BRASIL - ARMANDO D\'ARIENZO',
    address: 'R. ALM. MARQUES LEÃO, 684 - MORRO DOS INGLESES',
    cep: '01330-010 - FONE: 3284-4601 / 3541-3704'
  },
  {
    name: 'UBS BOM RETIRO - DR. OCTAVIO AUGUSTO RODOVALHO',
    address: 'R. TENENTE PENA, 8 - BOM RETIRO',
    cep: '01127-020 - FONE: 3222-0619 / 3224-9903'
  },
  {
    name: 'UBS CAMBUCI',
    address: 'AV. LACERDA FRANCO, 791 - CAMBUCI',
    cep: '01536-000 - FONE: 3276-6480 / 3209-3304'
  },
  {
    name: 'UBS REPÚBLICA',
    address: 'PRAÇA DA BANDEIRA, 15 - REPÚBLICA',
    cep: '01007-020 - FONE: 3101-0812'
  },
  {
    name: 'UBS BORACEA',
    address: 'R. BORACEA, 270 - SANTA CECÍLIA',
    cep: '01135-010 - FONE: 3392-1281 / 3392-1882'
  },
  {
    name: 'UBS SANTA CECÍLIA - DR. HUMBERTO PASCALE',
    address: 'R. VITORINO CARMILO, 599 - CAMPOS ELISEOS',
    cep: '01153-000 - FONE: 3826-0096 / 3826-7970'
  },
  {
    name: 'UBS SÉ',
    address: 'R. FREDERICO ALVARENGA, 259 - PQ. D. PEDRO II',
    cep: '01020-030 - FONE: 3101-8841 / 3101-8833'
  }
];

const Screening = ({
  setScreen,
  letter,
  campaign
}) => {
  const [step, setStep] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCreatingLetter, setCreateLetter] = useState(false);
  const [modal, setDownloadModal] = useState(false);

  const { identifier, token } = JSON.parse(window.localStorage.getItem(
    `${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`
  ));

  const user = jwt_decode(token);

  const chooseMonth = () => {
    const day = getMonth(new window.Date());

    switch (day) {
      case 0:
        return 'Janeiro';
      case 1:
        return 'Fevereiro';
      case 2:
        return 'Março';
      case 3:
        return 'Abril';
      case 4:
        return 'Maio';
      case 5:
        return 'Junho';
      case 6:
        return 'Julho';
      case 7:
        return 'Agosto';
      case 8:
        return 'Setembro';
      case 9:
        return 'Outubro';
      case 10:
        return 'Novembro';
      case 11:
        return 'Dezembro';
      default:
        return '';
    }
  };

  useEffect(() => {
    setStep('options');
  }, [])

  const handleNextStep = async () => {
    try {
      setLoading(true);

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/questionnaire`,
        data: {
          data: {
            answer: options.find(item => item.id === selectedOption).text
          }
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (selectedOption !== 5) {
        setScreen('campaigns');
        setStep('options');

        return;
      }

      setScreen('appointment');
      setLoading(false);
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const handlePdfDownload = async () => {
    setCreateLetter(true);


    const MyDoc = (
      <Document>
        <Page size="A4" orientation="portrait">
          <View style={{
            padding: 15,
          }}>
            <Image
              src={letterLogo}
              style={{
                width: 250,
              }}
            />
            <Text
              style={pdfStyles.title}
            >
              Declaração
            </Text>

            <Text
              style={pdfStyles.paragraph}
            >
              Declaramos ao Serviço Público de Saúde, que o (a) Sr.(a) {user.name} inscrito no CPF/MF nº  {cpfMask(identifier)}, e portador do RG nº: {rgMask(letter.rg)} é colaborador(a) da instituição, e  exerce a função de {letter.job} em áreas de exposição COVID-19, sendo elegível a vacinação seguindo os instrutivos normativos de priorização para vacinação no município de São Paulo - PMSP/ SMS / SEABEVS / COVISA/ SUVIS-SP:
            </Text>

            <View
              style={pdfStyles.instructions}
            >
              <Text
                style={pdfStyles.smallParagraph}
              >
                Instrutivo N.1 –  instrutivo para priorização da primeira remessa de doses da vacina de COVID-19 no Município de São Paulo - 18/01/2021
              </Text>

              <Text
                style={pdfStyles.smallParagraph}
              >
                Instrutivo N.2 –  2ª REMESSA DE VACINAS - 29/01/2021
              </Text>

              <Text
                style={pdfStyles.smallParagraph}
              >
                Instrutivo N.3 – Atual – 2º REMESSA DE VACINAS – atualizado em 02/02/2021
              </Text>

              <Text
                style={pdfStyles.smallParagraph}
              >
                Instrutivo N.4 – Abertura em UBS e Drive Thru – 04/02/2021
              </Text>

              <Text
                style={pdfStyles.smallParagraph}
              >
                Instrutivo N. 5 – Atualização em 16/02/2021: Abertura ao público em UBS e Drive Thru
              </Text>
            </View>

            <Text
              style={pdfStyles.paragraph}
            >
              Declaramos ainda, que a imunização do(a) colaborador(a) por meio da vacinação, é essencial para o fortalecimento das ações já adotadas por esse hospital, tais como treinamentos, orientações, utilização de barreiras de proteção, fornecimento de EPI’s, entre outros, evitando-se assim o contágio pelo COVID-19.
            </Text>

            <View
              style={pdfStyles.signature}
            >
              <Text
                style={pdfStyles.paragraph}
              >
                São Paulo, {getDay(new window.Date())} de {chooseMonth()} de 2021
              </Text>

              <Image
                src={letterSignature}
                style={{
                  width: 125,
                }}
              />

              <Text
                style={pdfStyles.paragraph}
              >
                Sociedade Beneficente de Senhoras Hospital Sírio-Libanês
              </Text>
              <Text
                style={pdfStyles.paragraph}
              >
                Gerente de Administração de Pessoal
              </Text>
              <Text
                style={pdfStyles.paragraph}
              >
                Alfredo Orlando Castilho
              </Text>
            </View>

            <View
              style={pdfStyles.footer}
            >
              <Text
                style={pdfStyles.smallParagraph}
              >
                Rua Dona Adma Jafet 91 – Bela vista – São Paulo/SP – CEP: 01308-050. Tel: (11) 3394-0200
              </Text>
              <Text
                style={pdfStyles.smallParagraph}
              >
                www.hospitalsiriolibanes.org.br
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );


    const blob = await pdf(MyDoc).toBlob();
    const link = document.createElement('a');
    link.download = 'Carta Admissão';

    link.href = window.URL.createObjectURL(blob);
    link.click();
    link.remove();

    setCreateLetter(false);
    setStep('letter-success');
  }

  const renderOptions = () => {
    if (campaign === 'COVID') {
      return options.map((item) => (
        <Item
          key={item.id}
          onClick={() => {
            setSelectedOption(item.id);
          }}
          selected={item.id === selectedOption}
        >
          <Paragraph
            selected={item.id === selectedOption}
          >
            {item.text.replaceAll('[]', 'COVID-19')}
          </Paragraph>
        </Item>
      ));
    } else {
      return options.slice(0, -1).map((item) => (
        <Item
          key={item.id}
          onClick={() => {
            setSelectedOption(item.id);
          }}
          selected={item.id === selectedOption}
        >
          <Paragraph
            selected={item.id === selectedOption}
          >
            {item.text.replaceAll('[]', campaign)}
          </Paragraph>
        </Item>
      ));
    }
  };

  const register = () => (
    <Fragment>
      <WarningAdvice>
        Para registro em prontuário, solicitamos que envie o documento comprovante de vacinação para o e-mail abaixo, informando o seu nome, matricula ou CPF e data de nascimento
      </WarningAdvice>
      <WarningEmail
        href={campaign === 'COVID' ? "mailto:vacinacaocovid19@hsl.org.br" : "vacinacaogripe@hsl.com.br"}
      >
        {campaign === 'COVID' ? 'vacinacaocovid19@hsl.org.br' : 'vacinacaogripe@hsl.com.br'}
      </WarningEmail>
    </Fragment>
  );

  const notRecommended = () => (
    <Fragment>
      <WarningMessage>
        Em caso de qualquer uma das seguintes condições, o uso da vacina adsorvida {campaign}(inativada) é contraindicado:
      </WarningMessage>
      <WarningBox>
        <p>
          {campaign === 'COVID'
            ? 'Alergia a qualquer um dos componentes desta vacina (hidróxido de alumínio, hidrogenofosfato dissódico, di-hidrogenofosfato de sódio, cloreto de sódio, água para injetáveis e hidróxido de sódio)'
            : 'ALERGIA a algum componente da vacina: Cepas de vírus Influenza fragmentados e inativado, Solução tampão isotônica de cloreto de sódio - fosfato de sódio, Formaldeído e Etoxilato de Octilfenol'
          }
        </p>
      </WarningBox>
      <WarningBox>
        <p>
          Pacientes com febre, doença aguda e início agudo de doenças crônicas
        </p>
      </WarningBox>
      <WarningAdvice>
        Nesses casos solicitamos que entre em contato com o time de saúde do colaborador através do e-mail
      </WarningAdvice>
      <WarningEmail
        href={campaign === 'COVID' ? "mailto:vacinacaocovid19@hsl.org.br" : "vacinacaogripe@hsl.com.br"}
      >
        {campaign === 'COVID' ? 'vacinacaocovid19@hsl.org.br' : 'vacinacaogripe@hsl.com.br'}
      </WarningEmail>
    </Fragment>
  );

  const symptoms = () => (
    <Fragment>
      <WarningMessage>
        Caso precise de orientação médica, solicitamos que entre em contato com o com o Programa Cuidando de Quem Cuida pelos telefones 0800-0074-746 ou (11)93068-3150
      </WarningMessage>
      <WarningAdvice>
        O agendamento/atendimento pode ser feito inicialmente pelo Adma ou WhatsApp
      </WarningAdvice>
    </Fragment>
  );

  const refused = () => {
    if (campaign === 'COVID') {
      return (
        <Fragment>
          <WarningMessage>
            Para registro, clique <a href="https://vacinacao-docs.s3.amazonaws.com/Termo-de-recusa-COVID-19-21.01.2021.pdf" download target="_blank" rel="noreferrer">aqui</a>, faça o download do Termo de Recusa, assine e mande uma imagem do documento para o e-email
          </WarningMessage>
          <WarningAdvice>
            vacinacaocovid19@hsl.org.br
          </WarningAdvice>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <WarningMessage>
          Ao apertar o botão confirmar você afirma que se opõe à vacinação. Caso tenha selecionado a opção errada, clique em alterar status.
        </WarningMessage>
        <WarningAdvice>
          vacinacaogripe@hsl.com.br
        </WarningAdvice>
      </Fragment>
    )
  }

  const volunteer = () => (
    <WarningMessage>
      Entre em contato com a equipe responsável pelo estudo ao qual você pertence e busque orientações.
    </WarningMessage>
  );

  const chooseWarning = () => {
    switch (selectedOption) {
      case 1:
        return register();
      case 2:
        return notRecommended();
      case 3:
        return symptoms();
      case 4:
        return refused();
      case 6:
        return volunteer();
      default:
        return null;
    }
  };

  const renderStep = () => {
    if (step === 'options') {
      return (
        <Fragment>
          <Title>
            Agendar Vacinação
        </Title>
          <Message>
            Selecione o item que representa seu status
        </Message>
          <List>
            {renderOptions()}
          </List>
        </Fragment>
      );
    };

    if (step === 'new-employee') {
      return (
        <Fragment>
          <Logo src={logo} />
          <ConfirmBox>
            <ConfirmTitle>
              Vacine-se contra a {campaign}
            </ConfirmTitle>
            <ConfirmMessage>
              Você está elegível para vacinação na UBS, para participar basta baixar sua carta de confirmação no botão abaixo e apresentá-la no momento da vacinação
            </ConfirmMessage>
          </ConfirmBox>
          {isLoading
            ? <Spinner src={spinner} />
            : <ConfirmButton
              onClick={handlePdfDownload}
            >
              {isCreatingLetter ? 'Carregando...' : 'Baixar carta'}
            </ConfirmButton>
          }
        </Fragment>
      )
    }

    if (step === 'letter-success') {
      return (
        <Fragment>
          <DownloadContainer
            backgroundColor="#CEDFE7"
            style={{ paddingBottom: '10px' }}
          >
            <DownloadTitle>
              Download Concluido
            </DownloadTitle>
            <DownloadName>
              {user.name}
            </DownloadName>
            <DownloadDescription>
              Vacinação na UBS contra {campaign}
            </DownloadDescription>
            <SuccessIconContainer>
              <SuccessIcon src={success} />
            </SuccessIconContainer>
            <DownloadMessage>
              Apresente sua declaração e crachá em uma UBS autorizada para receber sua 1ª dose de imunização
            </DownloadMessage>
            <DownloadButton
              onClick={() => setDownloadModal(true)}
            >
              Clique aqui para visualizar a lista de unidades
            </DownloadButton>
          </DownloadContainer>
        </Fragment>
      )
    }

    if (step === 'warning') {
      return (
        <Fragment>
          <Title>
            Atenção
          </Title>
          <WarningIconContainer>
            <WarningIcon src={warning} />
          </WarningIconContainer>
          {chooseWarning()}
          <ChangeStatus
            onClick={() => setStep('options')}
          >
            Alterar Status
          </ChangeStatus>
          {(selectedOption !== 5) && (
            <ConfirmButton
              onClick={handleNextStep}
            >
              Confirmar
            </ConfirmButton>
          )}
        </Fragment>
      );
    }
  }

  const renderUnities = () => {
    return unities.map((unity) => {
      return (
        <UnityItem>
          <span>
            {unity.name}
          </span>
          <span>
            {unity.address}
          </span>
          <span>
            {unity.cep}
          </span>
        </UnityItem>
      )
    });
  };

  return (
    <Container
      selected={selectedOption}
    >
      <Inner>
        {step !== 'confirmation' && (
          <Header
            hasClose={false}
          />
        )}
        {renderStep()}
        <NavigationContainer>
          <NavButton
            full={!selectedOption}
            back
            onClick={() => {
              setScreen('home')
            }}
          >
            Retornar
          </NavButton>
          {selectedOption && (
            <NavButton
              full={false}
              next
              onClick={() => {
                if (selectedOption !== 5) {
                  setStep('warning');

                  return;
                }

                handleNextStep();
              }}
            >
              Avançar
            </NavButton>

          )}
        </NavigationContainer>
      </Inner>
      {modal && (
        <Overlay>
          <Modal>
            <ModalTitle>
              Lista de Unidades
            </ModalTitle>
            <ModalDescription>
              Compareça em uma dessas unidades para receber sua primeira dose de imunização
            </ModalDescription>
            <ModalList>
              {renderUnities()}
            </ModalList>
            <ModalButton
              onClick={() => setDownloadModal(false)}
            >
              Fechar
            </ModalButton>
          </Modal>
        </Overlay>
      )}
    </Container>
  );
};

export default Screening;