// Libs
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Assets
import logo from '../../assets/logo.png';
import spinner from '../../assets/spinner.svg';

// Validation
import {
  cpfValidate,
  validateDate,
  cpfMask,
  dateMask,
} from '../../utils/validation';

const Container = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  padding-top: 2.5rem;
  background: #1B4487;
`;

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Logo = styled.img`
  display: block;
  width: 60%;
  margin: 2rem auto 0;
`;

const Title = styled.h1`
  margin: 2.5rem auto;
  color: #fff;
  text-align: center;
  font: 400 1.25rem 'Open Sans', sans-serif;
`;

const Form = styled.form`
  width: 80%;
  margin: 3rem auto 0;
`;

const Field = styled.fieldset`
  margin: 1rem 0;
  padding: 0;
  border: none;
`;

const Label = styled.label`
  display: block;
  margin-bottom: .5rem;
  color: #fff;
  font: 400 .9rem 'Open Sans', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  height: 55px;
  padding-left: 1rem;
  border: ${props => props.hasError ? '2px solid #D56161' : '2px solid #1B4487'};
  border-radius: 5px;
  color: ${props => props.hasError ? '#D56161' : '#1B4487'};
  font: 400 .9rem 'Open Sans', sans-serif;
  background-color: #C0D6FA;

  &::placeholder {
    color: #1B4487;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65px;
  margin: 2rem auto 0;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  color: #1B4487;
  font: 700 1.1rem 'Open Sans', sans-serif;
  background-color: #fff;
`;

const ErrorMessage = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #D56161;
  font: 400 .9rem 'Open Sans', sans-serif;
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const Login = ({
  history,
}) => {
  const [cpf, setCPF] = useState('');
  const [date, setDate] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [cpfError, setCpfError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [userError, setUserError] = useState(false);

  const applyCPFValidation = () => {
    if (`${process.env.REACT_APP_ENVIROMENT}` === 'prod') {
      return true;
    }

    return false;
  };

  const handleAuth = async (ev) => {
    ev.preventDefault();
    setLoading(true);

    try {
      const newDate = date.split('/');
      const formattedDate = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;

      if (applyCPFValidation()) {
        const cpfValidation = cpfValidate(cpf);
        const dateValidation = validateDate(formattedDate);

        setCpfError(!cpfValidation);
        setDateError(!dateValidation);

        if (!cpfValidation || !dateValidation) return;
      } else {
        const dateValidation = validateDate(formattedDate);

        setDateError(!dateValidation);

        if (!dateValidation) return;
      }

      setLoading(true);
      const object = new window.Date(formattedDate);
      const timestamp = window.Date.UTC(
        object.getUTCFullYear(),
        object.getUTCMonth(),
        object.getUTCDate(),
        object.getUTCHours(),
        object.getUTCMinutes(),
        object.getUTCSeconds()
      );

      const response = await axios.post(`${process.env.REACT_APP_AUTH_API}/login`, {
        identifier: cpf.replace(/[.-]/g, ''),
        birthday: timestamp,
      });

      window.localStorage.setItem(
        `${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`,
        JSON.stringify({
          identifier: cpf.replace(/[.-]/g, ''),
          token: response.data.data.token,
        })
      );

      setCpfError(false);
      setDateError(false);
      setLoading(false);

      history.replace('/app/');
    } catch (err) {
      setUserError(true);
      setLoading(false);
    }
  };

  const renderError = () => {
    if (userError) {
      return (
        <ErrorMessage>
          Usuário não cadastrado na primeira fase, por favor aguarde
        </ErrorMessage>
      );
    }

    return null;
  };

  return (
    <Container>
      <Inner>
        <Logo
          src={logo}
        />
        <Title>
          Campanha de Vacinação
        </Title>
        <Form
          onSubmit={handleAuth}
        >
          <Field>
            <Label>
              CPF ou RNE
            </Label>
            <Input
              type="text"
              inputMode="numeric"
              placeholder="digite seu CPF"
              required
              value={cpfMask(cpf)}
              hasError={cpfError}
              onChange={(ev) => {
                setCPF(ev.target.value);
              }}
              onBlur={(ev) => {
                if (applyCPFValidation()) {
                  const cpf = cpfMask(ev.target.value);
                  setCpfError(!cpfValidate(cpf));
                } else {
                  const isValid = ev.target.value.length === 14;
                  setCpfError(!isValid);
                }
              }}
            />
          </Field>
          <Field>
            <Label>
              Data de Nascimento
            </Label>
            <Input
              type="text"
              inputMode="numeric"
              placeholder="digite sua data de  nascimento"
              required
              value={dateMask(date)}
              hasError={dateError}
              onChange={(ev) => {
                setDate(ev.target.value);
              }}
              onBlur={(ev) => {
                const date = dateMask(ev.target.value);
                const newDate = date.split('/');
                const formattedDate = `${newDate[2]}-${newDate[1]}-${newDate[0]}`;
                const isValid = validateDate(formattedDate);
                setDateError(!isValid);
              }}
            />
          </Field>
          {renderError()}
          {isLoading 
            ? <Spinner src={spinner} />
            : <Button>
                Entrar
              </Button>
          }
        </Form>
      </Inner>
    </Container>
  );
};

export default Login;