// Libs
import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

// Assets
import logo from '../../assets/logo.png';
import spinner from '../../assets/spinner-white.svg';
import arrow from '../../assets/back.svg';

// Components
import UserHeader from '../../components/UserHeader';
import CampaignsList from '../../components/CampaignList';
import Card from './Card';
import Modal from './Modal';

const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  padding-bottom: 1rem;
  background-color: #1B4487;
`;

const InnerContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 3rem;

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

const Logo = styled.img`
  display: block;
  width: 60%;
  margin: 1rem auto 3rem;

  @media (min-width: 768px) {
    width: 40%;
  }
`;

const AppointmentList = styled.ul`
  position: relative;
  display: flex;
  width: 90%;
  max-width: 500px;
  margin: 1rem auto 0;

  ${({ scrollEnabled }) => scrollEnabled && `
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  `}
`;

const ArrowLeft = styled.button`
  position: absolute;
  top: 50%;
  left: -55px;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  background: url(${arrow}) no-repeat center;
  background-size: contain;
  transform: translateY(-50%) rotate(180deg);
`;

const ArrowRight = styled.button`
  position: absolute;
  top: 50%;
  right: -55px;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  background: url(${arrow}) no-repeat center;
  background-size: contain;
  transform: translateY(-50%);
`;

const Spinner = styled.img`
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

const options = [
  {
    id: 1,
    text: 'Já fui vacinado contra a [] em outro estabelecimento'
  },
  {
    id: 2,
    text: 'Possuo contraindicação à vacina ou a um de seus componentes'
  },
  {
    id: 3,
    text: 'Estou apresentando problemas respiratórios e/ou diagnóstico ativo de [] (mesmo que assintomático) há menos de duas semanas completas'
  },
  {
    id: 4,
    text: 'Não fui vacinado e me oponho a ser vacinado contra a []'
  },
  {
    id: 5,
    text: 'Não possuo disponibilidade de horário'
  }
];

const Home = ({
  setScreen,
  campaign,
  campaigns,
  selectedCampaign,
  campaignLoader,
  checkAppointment,
  setCampaign,
}) => {
  const [appointments, setAppointmentList] = useState([]);
  const [selectedAppointment, setAppointment] = useState({});
  const [symptoms, setSymptoms] = useState([]);
  const [confirmActive, setConfirmActive] = useState(false);
  const [modal, setModal] = useState({
    type: '',
    isOpen: false,
  });
  const [deviceType, setDeviceType] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isLoadingConfirmation, setLoadingConfirmation] = useState(false);
  const [isLoadingReaction, setLoadingReaction] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);

  const { token, identifier } = JSON.parse(window.localStorage.getItem(
    `${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`
  ));

  const info = jwt_decode(token);

  const getAppointment = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const appointments = response.data.data;

      setAppointmentList(appointments);
      setAppointment({
        ...appointments[0],
      });
      setShowCampaigns(true);
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const getSymptoms = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_VACCINATION_API}/symptoms`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSymptoms(
        response.data.data
      );
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setDeviceType('mobile');
    } else {
      setDeviceType('desktop');
    }

    getAppointment();
    getSymptoms();
  }, []);

  const cancelAppointment = async () => {
    try {
      setLoading(true);

      const selectedAppointment = appointments.find(item => (item.type === modal.campaign.type) && (item.vaccineDose === modal.campaign.vaccineDose))

      await axios.delete(
        `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination/${selectedAppointment.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === selectedAppointment.id) {
          return {
            ...appointment,
            status: 'Cancelado',
          }
        }

        return appointment;
      });

      setAppointmentList(updatedAppointments);

      setAppointment({
        ...selectedAppointment,
        status: 'Cancelado'
      });

      setLoading(false);

      const selectedCampaign = campaigns.find(item => item.type === selectedAppointment.type);

      setModal({
        type: 'cancel-success',
        isOpen: true,
        campaign: selectedCampaign
      });
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const markNewAppointment = () => {
    setScreen('appointment');
  };

  const remarkAppointment = async (appointment) => {
    try {
      const { id } = selectedAppointment;

      const selectedCampaign = campaigns.find(item => item.type === appointment.type && item.vaccineDose === appointment.vaccineDose);

      setCampaign(null);
      setScreen('appointment');
      setCampaign(selectedCampaign);

      await axios.delete(
        `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const sendAppointmentConfirmation = async (openQR, appointment) => {
    try {
      const { id } = selectedAppointment;

      const selectedCampaign = campaigns.find(item => item.type === appointment.type && item.vaccineDose === appointment.vaccineDose);

      setCampaign(null);
      setModal({});
      setLoadingConfirmation(true);
      setCampaign(selectedCampaign);

      await axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination/${id}`,
        data: {
          data: {
            status: 'Realizado'
          }
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (openQR) {
        setModal({
          type: 'qr-code',
          isOpen: true,
        });
      } else {
        setLoadingConfirmation(false);
        setAppointment({});
        setAppointmentList([]);

        getAppointment();
      }

    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  }

  const sendVaccineManufacturer = async (manufacturer, lot) => {
    try {
      const { id } = selectedAppointment;

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination/${id}/vaccine`,
        data: {
          data: {
            manufacturer,
            lot
          }
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setAppointment({});
      setAppointmentList([]);

      getAppointment();
      setModal({});
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const confirmAppointment = (appointment) => {
    try {
      const { qrCode } = selectedAppointment;

      if (Number(qrCode)) {
        sendAppointmentConfirmation(true, appointment);
      } else {
        sendAppointmentConfirmation(false, appointment);
      }
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const handleQuestionaire = async (id) => {
    try {
      setModal({});

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/questionnaire`,
        data: {
          data: {
            answer: options.find(item => item.id === id).text.replace('[]', campaign)
          }
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const informReactions = async (symptoms, severity, description) => {
    try {
      const { id } = selectedAppointment;

      setLoadingReaction(true);

      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_VACCINATION_API}/person/${identifier}/vaccination/${id}/symptoms`,
        data: {
          data: {
            symptoms,
            severity,
            description,
          }
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setLoadingConfirmation(false);
      setAppointment({});
      setAppointmentList([]);

      getAppointment();

      setModal({
        type: 'reaction-success',
        isOpen: true,
      });
      setLoadingReaction(false);
    } catch (err) {
      window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
      window.location = `${window.location.origin}/login`;
    }
  };

  const renderAppointments = () => {
    if (deviceType === 'mobile') {
      return appointments.map((appointment, index) => {
        return (
          <Card
            key={`${appointment.id}`}
            index={index}
            appointment={appointment}
            name={info.name}
            isLoadingConfirmation={isLoadingConfirmation}
            deviceType={deviceType}
            confirmActive={confirmActive}
            setConfirmActive={setConfirmActive}
            setAppointment={setAppointment}
            campaigns={campaigns}
            setCampaign={setCampaign}
            setModal={setModal}
            remarkAppointment={remarkAppointment}
            confirmAppointment={confirmAppointment}
            markNewAppointment={markNewAppointment}
          />
        );
      });
    } else {
      return (
        <Card
          key={`${selectedAppointment.status}-${1}`}
          index={1}
          appointment={selectedAppointment}
          name={info.name}
          isLoadingConfirmation={isLoadingConfirmation}
          deviceType={deviceType}
          confirmActive={confirmActive}
          setConfirmActive={setConfirmActive}
          setAppointment={setAppointment}
          campaigns={campaigns}
          setCampaign={setCampaign}
          setModal={setModal}
          remarkAppointment={remarkAppointment}
          confirmAppointment={confirmAppointment}
          markNewAppointment={markNewAppointment}
        />
      );
    }
  };

  const renderContent = () => {
    if (appointments.length > 0) {
      const curr = appointments.find(item => item.id === selectedAppointment.id);
      const index = appointments.indexOf(curr);

      return (
        <Fragment>
          <AppointmentList
            id='appointment-cards'
            scrollEnabled={deviceType === 'mobile'}
          >
            {deviceType !== 'mobile' && index > 0 && (
              <ArrowLeft
                onClick={() => {
                  setAppointment(appointments[index - 1]);
                }}
              />
            )}
            {renderAppointments()}
            {deviceType !== 'mobile' && index < appointments.length - 1 && (
              <ArrowRight
                onClick={() => {
                  setAppointment(appointments[index + 1]);
                }}
              />
            )}
          </AppointmentList>
        </Fragment>
      );
    } else {
      return (
        <Spinner
          src={spinner}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      );
    }
  }

  return (
    <Container>
      <UserHeader
        background="#FFFFFF41"
        color="#8198BD"
      />
      <InnerContainer>
        <Logo src={logo} />
        {renderContent()}
        {showCampaigns && (
          <CampaignsList
            setScreen={setScreen}
            campaigns={campaigns}
            appointments={appointments}
            campaignLoader={campaignLoader}
            checkAppointment={checkAppointment}
          />
        )}
      </InnerContainer>
      {modal.isOpen && (
        <Modal
          modal={modal}
          selectedAppointment={selectedAppointment}
          symptoms={symptoms}
          isLoading={isLoading}
          isLoadingReaction={isLoadingReaction}
          setModal={setModal}
          setLoadingConfirmation={setLoadingConfirmation}
          handleQuestionaire={handleQuestionaire}
          cancelAppointment={cancelAppointment}
          informReactions={informReactions}
          sendAppointmentConfirmation={sendAppointmentConfirmation}
          sendVaccineManufacturer={sendVaccineManufacturer}
        />
      )}
    </Container>
  );
};

export default Home;