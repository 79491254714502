// Libs
import React from 'react';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 33px;
  padding: 0 2.5%;
  background: #FFFFFF41 0% 0% no-repeat padding-box;

  @media (min-width: 768px) {
    height: 40px;
  }
`;

const Name = styled.span`
  color: #8198BD;
  font: 700 .8rem 'Open Sans', sans-serif;
`;

const Logout = styled.button`
  border: none;
  outline: none;
  color: #8198BD;
  cursor: pointer;
  font: 700 .8rem 'Open Sans', sans-serif;
  background: none;
`;

const UserHeader = ({
  applyPosition,
  color,
}) => {
  const { token } = JSON.parse(window.localStorage.getItem(
    `${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`
  ));
  
  const info = jwt_decode(token);

  const handleLogout = () => {
    window.localStorage.removeItem(`${process.env.REACT_APP_LOCALSTORAGE_CREDENTIALS}`);
    window.location = `${window.location.origin}/login`;
  };

  return (
    <Container
      style={{
        ...applyPosition && {
          position: 'fixed',
          top: '0',
          left: '0'
        }
      }}
    >
      <Name
        color={color}
      >
        {info.name}
      </Name>
      <Logout
        color={color}
        onClick={handleLogout}
      >
        Sair
      </Logout>
    </Container>
  );
};

export default UserHeader;